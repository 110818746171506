import React, { useEffect, useState } from "react";

import { NavLink, useNavigate } from "react-router-dom";

import ImportScript from "../Utils/ImportScript";

import getRequest from "../Utils/api";

import { SiYourtraveldottv } from "react-icons/si";

import { MdMeetingRoom } from "react-icons/md";

import { FaFileSignature } from "react-icons/fa";

import { BsFillPeopleFill } from "react-icons/bs";
import { GiPayMoney } from "react-icons/gi";

const Sidebar = () => {
  const navigate = useNavigate();

  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));

  const [state, setState] = useState(LocalStorageData);

  const scriptArrsy = ["/assets/js/misc.js"];

  ImportScript(scriptArrsy);

  const [counterList, setCounterList] = useState([]);

  useEffect(() => {
    async function getCounterList() {
      const endpoint = `/documents_counter/${LocalStorageData?.user_id}/${LocalStorageData?.emp_id}/${LocalStorageData?.zoho_role}/${LocalStorageData?.email}`;

      const response = await getRequest(
        endpoint,
        LocalStorageData?.generate_auth_token
      );

      if (response.success) {
        setCounterList(response.data);
      } else {
        if (
          response.error &&
          response.error.response &&
          response.error.response.status === 500
        ) {
          navigate("/error_500");
        } else {
          navigate("/error_403");
        }
      }
    }

    getCounterList();
  }, []);

  return (
    <>
      <nav className="sidebar sidebar-offcanvas " id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile">
            <NavLink className="nav-link" to="/">
              <div className="nav-profile-image">
                <img src={LocalStorageData?.photo} alt="image" />

                <span className="login-status online"></span>
              </div>

              <div className="nav-profile-text d-flex flex-column">
                <span className="font-weight-bold mb-2">
                  {LocalStorageData?.owner_name}
                </span>

                <span className="text-secondary text-small">
                  {LocalStorageData?.email}
                </span>
              </div>
            </NavLink>
          </li>
          {state?.zoho_role !== "Accounts" ? (
            <>
              {state?.zoho_role !== "Team member" && (
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="collapse"
                    href="#ui-basic"
                    aria-expanded="false"
                    aria-controls="ui-basic"
                  >
                    <span class="menu-title">Employee</span>

                    <i class="menu-arrow" style={{ color: "#d14124" }}></i>

                    <BsFillPeopleFill
                      className="fs-6"
                      style={{ color: "#d14124" }}
                    />
                  </a>

                  <div class="collapse" id="ui-basic">
                    <ul className="nav flex-column sub-menu">
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/user_list/active_employee"
                        >
                          Employee List ({counterList?.Active_Users})
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/user_list/pending_onboarding_employee"
                        >
                          Pending Onboarding ({counterList?.Pending_Onboarding})
                        </NavLink>
                      </li>

                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/user_list/pending_offboarding_employee"
                        >
                          Pending Offboarding (
                          {counterList?.Pending_Offboarding})
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </li>
              )}

              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#ui-form"
                  aria-expanded="false"
                  aria-controls="ui-basic"
                >
                  <span class="menu-title">Tax Forms</span>

                  <i class="menu-arrow" style={{ color: "#d14124" }}></i>

                  <FaFileSignature
                    className="fs-6"
                    style={{ color: "#d14124" }}
                  />
                </a>

                <div class="collapse" id="ui-form">
                  <ul class="nav flex-column sub-menu">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/form12bb">
                        Form 12 BB
                      </NavLink>
                    </li>

                    {(LocalStorageData?.zoho_role === "Finance" ||
                      LocalStorageData?.zoho_role === "Admin") && (
                      <>
                        <li className="nav-item">
                          <NavLink className="nav-link" to="/get_form12bb_data">
                            Download Form <br />
                            12 BB Data
                          </NavLink>
                        </li>

                        {/* <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/get_form_flexi_benefit_data"
                        >
                          Download Form <br /> Flexible Benefit Data
                        </NavLink>
                      </li> */}
                      </>
                    )}
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#ui-travel_request"
                  aria-expanded="false"
                  aria-controls="ui-basic"
                >
                  <span class="menu-title">Travel Request</span>

                  <i class="menu-arrow" style={{ color: "#d14124" }}></i>

                  <SiYourtraveldottv
                    className="fs-6"
                    style={{ color: "#d14124" }}
                  />
                </a>

                <div class="collapse" id="ui-travel_request">
                  <ul class="nav flex-column sub-menu">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/alltravelrequest">
                        My Requests ({counterList?.Travel_Request_By_User_ID})
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/travelrequestform">
                        Create Request
                      </NavLink>
                    </li>

                    {(LocalStorageData?.zoho_role === "Management" ||
                      LocalStorageData?.zoho_role === "Admin") && (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/travelrequestreceived"
                        >
                          Approval/Decline
                          <br /> Request (
                          {counterList?.Travel_Request_For_Approval_and_Decline}
                          )
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </li>

              <li class="nav-item">
                <NavLink className="nav-link" to="/cabin_slot_booking">
                  <span className="menu-title">Cabin Booking</span>

                  <i class="menu-arrow"></i>

                  <MdMeetingRoom
                    className="fs-6"
                    style={{ color: "#d14124" }}
                  />
                </NavLink>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link"
                  data-bs-toggle="collapse"
                  href="#ui-expense_request"
                  aria-expanded="false"
                  aria-controls="ui-basic"
                >
                  <span class="menu-title">Expenses</span>

                  <i class="menu-arrow" style={{ color: "#d14124" }}></i>

                  <GiPayMoney className="fs-6" style={{ color: "#d14124" }} />
                </a>

                <div class="collapse" id="ui-expense_request">
                  <ul class="nav flex-column sub-menu">
                    <li className="nav-item">
                      <NavLink className="nav-link" to="/view_expense">
                        View Expenses
                      </NavLink>
                    </li>

                    <li className="nav-item">
                      <NavLink className="nav-link" to="/create_expense">
                        Submit Expense
                      </NavLink>
                    </li>

                    {(LocalStorageData?.zoho_role === "Management" ||
                      (LocalStorageData?.zoho_role === "Admin" &&
                        LocalStorageData?.zoho_role !== "Finance")) && (
                      <li className="nav-item">
                        <NavLink
                          className="nav-link"
                          to="/managmentviewexpense/"
                        >
                          Approval/Decline
                          <br /> Request ( Management )
                        </NavLink>
                      </li>
                    )}
                    {(LocalStorageData?.zoho_role === "Management" ||
                      LocalStorageData?.zoho_role === "Finance") && (
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/financeviewexpense/">
                          Approval/Decline
                          <br /> Request ( Finance )
                        </NavLink>
                      </li>
                    )}
                  </ul>
                </div>
              </li>
            </>
          ) : (
            <li class="nav-item">
              <a
                class="nav-link"
                data-bs-toggle="collapse"
                href="#ui-expense_request"
                aria-expanded="false"
                aria-controls="ui-basic"
              >
                <span class="menu-title">Expenses</span>

                <i class="menu-arrow" style={{ color: "#d14124" }}></i>

                <GiPayMoney className="fs-6" style={{ color: "#d14124" }} />
              </a>

              <div class="collapse" id="ui-expense_request">
                <ul class="nav flex-column sub-menu">
                  <li className="nav-item">
                    <NavLink className="nav-link" to="/accounts_expense">
                      View All Expenses
                    </NavLink>
                  </li>
                </ul>
              </div>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Sidebar;
