import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const Private_Routes = ({ allowedRoles, children }) => {
  const navigate = useNavigate();
  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));
  const role = LocalStorageData?.zoho_role;
  const isAuthorized = allowedRoles?.some((roles) => roles?.includes(role));
  useEffect(() => {
    if (!isAuthorized) {
      navigate("/error_404");
    }
    if (!localStorage.getItem("loggedin")) {
      navigate("/error_403");
    }
  }, [navigate]);
  return children;
};

export default Private_Routes;
