import React, { useState } from "react";
import * as XLSX from "xlsx";
import { AiOutlineCloudUpload } from "react-icons/ai";
const Expense_upload = ({ handleGetExcelData, clientUploadIndex }) => {
  console.log("comp", clientUploadIndex);
  const [excelFile, setExcelFile] = useState(null);

  const [typeError, setTypeError] = useState(null);

  // onchange event for excel

  const handleFile = (e) => {
    let fileTypes = [
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "text/csv",
    ];

    let selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        setTypeError(null);

        let reader = new FileReader();

        reader.readAsArrayBuffer(selectedFile);

        reader.onload = (e) => {
          const excelData = e.target.result;

          const workbook = XLSX.read(excelData, { type: "buffer" });

          const worksheetName = workbook.SheetNames[0];

          const worksheet = workbook.Sheets[worksheetName];

          const data = XLSX.utils.sheet_to_json(worksheet, {
            dateNF: "dd-mm-yyyy",
          });

          // Now 'data' contains the parsed Excel data

          // Process 'data' as needed, for example, set it in state
          setExcelFile(data);
          handleGetExcelData(data);
        };
      } else {
        setTypeError("Please select only excel file types");

        setExcelFile(null);
      }
    } else {
      console.log("Please select your file");
    }
  };

  console.log(excelFile);
  return (
    <div className="wrapper">
      <form className="form-group custom-form col-3">
        <label for="excelUpload">
          <AiOutlineCloudUpload style={{ fontSize: "2rem" }} />{" "}
          <small>Upload data from Excel</small>
        </label>
        <input
          type="file"
          id="excelUpload"
          className="form-control d-none"
          required
          onChange={handleFile}
        />
        {/* <button onClick={handleFileSubmit} className="btn btn-success btn-md">
          Submit
        </button> */}
      </form>

      {typeError && (
        <div className="alert alert-danger" role="alert">
          {typeError}
        </div>
      )}
    </div>
  );
};

export default Expense_upload;
