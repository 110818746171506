import React, { useState, forwardRef } from "react";
import Navbar from "../../Partials/Navbar";
import Sidebar from "../../Partials/Sidebar";
import { useAlert } from "react-alert";
import getRequest, { postRequest } from "../../Utils/api";
import Select from "react-select";
import { useNavigate, NavLink } from "react-router-dom";
import { useEffect } from "react";
import classNames from "classnames";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { travel_request_form_validation } from "../../Utils/Validation_Form";

import "react-tooltip/dist/react-tooltip.css";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
// testint

const travelMode = ["Flight", "Train", "Intercity Cab"];

// =====================Data End=============
const TravelRequestForm = () => {
  const [projects, setProjects] = useState([]);
  const [rows, setRows] = useState([{ id: 1, data: { trip_type: "OneWay" } }]);

  const [selectedClient, setSelectedClient] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState([]);
  const [projectDetails, setProjectDetails] = useState([]);
  const navigate = useNavigate();
  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));
  const alert = useAlert();
  let [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(travel_request_form_validation),
  });
  let [allData, setAllData] = useState([]);
  let [cityData, setCityData] = useState([]);
  const [employeeId, setEmployeeId] = useState("");
  const [getEmployeeDataByStatusCode, setEmployeeDataByStatusCode] = useState(
    []
  );
  // const [
  //   getEmployeeDataByStatusCodeForRooms,
  //   setEmployeeDataByStatusCodeForRooms,
  // ] = useState([]);
  const [getEmployeeFilteredData, setEmployeeFilteredData] = useState([]);
  const [getEmployeeDefaultByStatusCode, setEmployeeDefaultByStatusCode] =
    useState({});
  const [error, setError] = useState(false);
  const [checkError, setCheckError] = useState(false);
  const [travellersData, setTravellersData] = useState([]);
  const [validateForBadicDetailsDataRow, setValidateForBadicDetailsDataRow] =
    useState({});
  const [validateForTravellersDataRow, setValidateForTravellersDataRow] =
    useState([]);
  const [validateForTravelDataRow, setValidateForTravelDataRow] = useState([]);
  const [validateForAccommodationDataRow, setValidateForAccommodationDataRow] =
    useState([]);
  const [validateForOccupancyDataRow, setValidateForOccupancyDataRow] =
    useState([]);
  const [travellerRowId, setTravellerRowId] = useState("");
  const [numberValue, setNumberValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedClientId, setSelectedClientId] = useState("");
  const [filteredProjectIds, setFilteredProjectIds] = useState([]);
  const [treavellerRadioButton, setTreavellerRadioButton] = useState(true);
  const [accommodationRadioButton, setAccommodationRadioButton] =
    useState(false);
  const [roomsData, setRoomsData] = useState([
    {
      id: 1,
      data: { is_employee: "Yes", dob: new Date(), emp_id: "" },
    },
  ]);

  const [numberOfRoomsRequired, setNumberOfRoomsRequired] = useState(1);
  const [accommodationData, setAccommodationData] = useState([
    {
      id: 1,
      isOpen: false,
      number_of_rooms: 1,
      data: {
        // number_of_rooms: 1,
        // number_of_adults: 1,
        // number_of_children: 0,
        // checkIn: "",
        // checkOut: "",
      },
    },
  ]);
  const [testData, setTestData] = useState([]);
  // console.log("roomsData", roomsData);
  // console.log("testData", testData);
  const [showDropdown, setShowDropdown] = useState(false);
  const [filterText, setFilterText] = useState("");

  // ================================================================================================================
  // ==========For Top Level Details==============
  const [basicDetails, setBasicDetails] = useState({
    billable: "",
    client_id: "",
    project_id: "",
    reason_for_travel: "",
    special_request: "",
    booking_for: "self",
  });
  function formatBirthdate(birthdate) {
    var parts = birthdate.split("/");
    var formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];

    return formattedDate;
  }

  //HandleROomsChangeforROoms

  const handleInputRoomChange = (e) => {
    setNumberOfRoomsRequired(e.target.value);
  };

  //Fetching Project Codes and IDS

  const fetchProjectDetails = async () => {
    try {
      const endpoint = "/client_project";
      const response = await getRequest(
        endpoint,
        LocalStorageData?.generate_auth_token
      );

      if (response.success) {
        setProjectDetails(response.data);
      } else {
        // Handle the error here, e.g., display an error message
        console.error(`Failed to fetch project details: ${response.message}`);
      }
    } catch (error) {
      // Handle unexpected errors here
      console.error("An error occurred while fetching project details:", error);
    }
  };
  const fetchClientsData = async () => {
    try {
      const endpoint = "/clients"; // Define the API endpoint
      const response = await getRequest(
        endpoint,
        LocalStorageData?.generate_auth_token
      );

      if (response.success) {
        setProjects(response.data);
      } else {
        // Handle the error here, e.g., display an error message
        console.error(`Failed to fetch clients data: ${response.message}`);
      }
    } catch (error) {
      // Handle unexpected errors here
      console.error("An error occurred while fetching clients data:", error);
    }
  };
  const handleClientChange = (event) => {
    const clientName = event.target.value;
    // setBasicDetails(basicDetails?.client_id = clientName)
    const updatedStateObject = { ...basicDetails, client_id: clientName };
    setBasicDetails(updatedStateObject);

    // basicDetails?.client_id(clientName);
    // Filter the projects based on the selected client name
    const filteredProjects = projectDetails.filter(
      (project) => project.client_name === clientName
    );

    // Do something with the filteredProjects if needed

    // Assuming you want to set the first project from the filtered list as the selected project
    setFilteredProjectIds(filteredProjects);
  };

  useEffect(() => {
    // setStatus_code(status_code);
    setLoading(true);

    async function get_user_list() {
      try {
        const result_user_list = await getRequest(
          "/user_list/active_employee",
          LocalStorageData?.generate_auth_token
        );

        let employ = result_user_list.data.map((val) => ({
          value: val["Employee ID"],
          label: `${val["Employee ID"]}-${val.ownerName}`,
        }));

        if (basicDetails?.booking_for === "others") {
          let filt = employ.filter(
            (rest) => rest.value !== LocalStorageData?.emp_id
          );
          setEmployeeDataByStatusCode(filt);
        } else {
          let filt = employ.filter(
            (rest) => rest.value === LocalStorageData?.emp_id
          );
          setEmployeeDataByStatusCode(employ);
          setEmployeeDefaultByStatusCode(filt);
        }

        setLoading(false);
      } catch (err) {
        if (err?.response?.status === 500) {
          navigate("/error_500");
        } else {
          navigate("/error_403");
        }
      }
    }

    get_user_list();
    fetchClientsData();
    fetchProjectDetails();
    setAccommodationData([
      {
        id: 1,
        number_of_rooms: numberOfRoomsRequired,
        data: {},
      },
    ]);
  }, [basicDetails?.booking_for]);

  useEffect(() => {
    const fetchEmployeeData = async () => {
      setLoading(true);

      try {
        const endpoint =
          basicDetails?.booking_for === "others"
            ? `/get_employee_details_for_travel/0`
            : `/get_employee_details_for_travel/${LocalStorageData?.emp_id}`;

        const res = await getRequest(endpoint);

        const resObj = {
          id: 1,
          data: {
            is_employee: "Yes",
            emp_id:
              (res.data.length > 0 && treavellerRadioButton) ||
              (res.data.length > 0 && accommodationRadioButton)
                ? {
                    value: res.data[0]?.["Employee ID"],
                    label: `${res.data[0]?.["Employee ID"]}`,
                  }
                : "",
            name:
              (res.data.length > 0 && treavellerRadioButton) ||
              (res.data.length > 0 && accommodationRadioButton)
                ? res?.data[0]?.ownerName
                : "",
            gender:
              (res.data.length > 0 && treavellerRadioButton) ||
              (res.data.length > 0 && accommodationRadioButton)
                ? res?.data[0]?.Gender
                : "",
            phone:
              (res.data.length > 0 && treavellerRadioButton) ||
              (res.data.length > 0 && accommodationRadioButton)
                ? res?.data[0]?.["Personal Mobile Number"]
                : "",
            email:
              (res.data.length > 0 && treavellerRadioButton) ||
              (res.data.length > 0 && accommodationRadioButton)
                ? res?.data[0]?.["Email address"]
                : "",
            dob:
              (res.data.length > 0 && treavellerRadioButton) ||
              (res.data.length > 0 && accommodationRadioButton)
                ? formatBirthdate(res?.data[0]?.["Date of Birth"])
                : "1990-01-01",
          },
        };

        setTravellersData([resObj]);
        setRoomsData([resObj]);
        setLoading(false);
        setIsOpen(false);
      } catch (err) {
        if (err.response?.status === 500) {
          navigate("/error_500");
        } else {
          navigate("/error_403");
        }
      }
    };

    fetchEmployeeData();
  }, [
    employeeId,
    basicDetails?.booking_for,
    // !treavellerRadioButton,
    // !accommodationRadioButton,
  ]);

  useEffect(() => {
    async function getCountry() {
      setLoading(true);
      try {
        const res = await getRequest(
          "/airport",
          LocalStorageData?.generate_auth_token
        );

        const citys = res.data.map((val) => ({
          value: `${val.city_name} (${val.airport_code})`,
          label: `${val.city_name} (${val.airport_code})`,
        }));

        console.log("ressss", citys);
        setCityData(citys);
      } catch (err) {
        if (err.response?.status === 500) {
          navigate("/error_500");
        } else {
          navigate("/error_403");
        }
      }
      setLoading(false);
    }

    getCountry();
  }, []);

  // ================For Travel Details===========
  // const [travel, setTravel] = useState({
  //   travel_mode: "",
  //   travel_from_city: "",
  //   travel_to_city: "",
  //   departure: "",
  //   return: "",
  // });

  const [travelType, setTravelType] = useState({
    one_way: true,
    round_trip: false,
    // multi_city: false,
  });

  const handleTravelTypeChange = (e) => {
    const name = e.target.name;
    const checked = e.target.checked;

    // Set the selected radio button to true and others to false
    setTravelType({
      ...travelType,
      [name]: checked,
      one_way: name === "one_way" ? checked : false,
      round_trip: name === "round_trip" ? checked : false,
      // multi_city: name === "multi_city" ? checked : false,
    });
  };

  //
  //
  //
  //
  // ========For Travel Request========

  const handleAddRow = () => {
    const newRow = {
      id: rows[rows?.length - 1]?.id + 1,
      data: { trip_type: "OneWay" },
    };
    setRows([...rows, newRow]);
  };

  const handleDataChange = (id, newData) => {
    const updatedRows = rows.map((row) =>
      row.id === id ? { ...row, data: newData } : row
    );
    setRows(updatedRows);
  };
  //
  //
  //
  //
  //
  // ==========For Travellers======

  const handleAddTraveller = (e) => {
    e.preventDefault();
    const newRow = {
      id: travellersData[travellersData?.length - 1]?.id + 1,
      data: {
        is_employee: "Yes",
        dob: new Date(),
        emp_id: "",
      },
    };
    setTravellersData([...travellersData, newRow]);
    setTravellerRowId(travellersData.length);
  };
  // { ...row.data, is_employee: "Yes", newData }

  const handleTravellerChange = async (id, newData) => {
    const updatedTraveller = travellersData.map((row) =>
      row.id === id ? { ...row, data: newData } : row
    );
    setTravellersData(updatedTraveller);

    if (newData.emp_id) {
      try {
        const response = await getRequest(
          `/get_employee_details_for_travel/${newData.emp_id?.value}`
        );

        if (response.success) {
          const apiData = response.data;
          const updatedRow = {
            id: id,
            data: {
              ...newData,
              emp_id: {
                value: apiData[0]["Employee ID"],
                label: `${apiData[0]["Employee ID"]}`,
              },
              is_employee: "Yes",
              name: apiData[0]["ownerName"],
              gender: apiData[0]["Gender"],
              phone: apiData[0]["Personal Mobile Number"],
              email: apiData[0]["Email address"],
              dob: formatBirthdate(apiData[0]["Date of Birth"]),
            },
          };

          const updatedRows = travellersData.map((row) =>
            row.id === id ? updatedRow : row
          );
          setTravellersData(updatedRows);
        } else {
          console.error("Error fetching data from API:", response.message);
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    }
  };

  const handleTravellerDeleteRow = (id) => {
    const updatedRows = travellersData.filter((row) => row.id !== id);
    setTravellersData(updatedRows);
  };

  const handleDeleteRow = (id) => {
    const updatedRows = rows.filter((row) => row.id !== id);
    setRows(updatedRows);
  };
  //
  //
  //
  //
  //
  //
  // ================For Accomendation================

  const handleAddAccommodation = () => {
    const newRow = {
      id: accommodationData.length + 1,
      data: {},
    };
    setAccommodationData([...accommodationData, newRow]);
  };

  const handleAccommodationChange = (id, newData) => {
    const updatedAccommodation = accommodationData.map((row) =>
      row.id === id ? { ...row, data: { ...row.data, ...newData } } : row
    );
    setAccommodationData(updatedAccommodation);
  };

  const handleAccommodationDeleteRow = (id) => {
    const updatedRows = accommodationData.filter((row) => row.id !== id);
    setAccommodationData(updatedRows);
  };
  //
  //
  //
  //
  //
  //

  // ==================For Rooms=====================

  // roomsData.push({ id: 1, data: { is_employee: "Yes" } });

  const handleAddRoom = () => {
    const newRow = {
      id: roomsData[roomsData?.length - 1]?.id + 1,
      data: { is_employee: "No", dob: new Date() },
    };
    setRoomsData([...roomsData, newRow]);
  };

  const handleRoomChange = async (id, newData) => {
    const updatedRooms = roomsData.map((row) =>
      row.id === id ? { ...row, data: newData } : row
    );
    setRoomsData(updatedRooms);

    if (newData.emp_id) {
      try {
        const response = await getRequest(
          `/get_employee_details_for_travel/${newData.emp_id?.value}`
        );

        if (response.success) {
          const apiData = response.data;
          const updatedRow = {
            id: id,
            data: {
              ...newData,
              is_employee: "Yes",
              emp_id: {
                value: apiData[0]["Employee ID"],
                label: `${apiData[0]["Employee ID"]}`,
              },
              rooms: apiData[0]["room"],
              name: apiData[0]["ownerName"],
              gender: apiData[0]["Gender"],
              phone: apiData[0]["Personal Mobile Number"],
              email: apiData[0]["Email address"],
              dob: formatBirthdate(apiData[0]["Date of Birth"]),
            },
          };

          const updatedRows = roomsData.map((row) =>
            row.id === id ? updatedRow : row
          );
          setRoomsData(updatedRows);
        } else {
          console.error("Error fetching data from API:", response.message);
        }
      } catch (error) {
        console.error("Error fetching data from API:", error);
      }
    }
  };

  // const handleRoomDeleteRow = (id) => {
  //   const updatedRows = roomsData.filter((row) => row.id !== id);
  //   setRoomsData(updatedRows);
  // };

  const inputEvent = (event) => {
    const { name, value } = event.target;
    if (name === "booking_for") {
      setRows([
        {
          id: 1,
          data: {
            travel_mode: "",
            travel_from_city: "",
            travel_to_city: "",
            departure: "",
            return: "",
            preferred_time: "",
            return_preferred_time: "",
            trip_type: "OneWay",
          },
        },
      ]);
      setAccommodationData([
        {
          id: 1,
          number_of_rooms: numberOfRoomsRequired,
          data: {
            city: "",
            checkIn: "",
            checkOut: "",
            breakfastRequired: "",

            // checkIn: "",
            // checkOut: "",
          },
        },
      ]);

      setBasicDetails({
        billable: "",
        client_id: "",
        project_id: "",
        reason_for_travel: "",
        special_request: "",
      });
      setBasicDetails((preValue) => {
        return {
          ...preValue,
          [name]: value,
        };
      });
    } else {
      setBasicDetails((preValue) => {
        return {
          ...preValue,
          [name]: value,
        };
      });
    }
  };

  useEffect(() => {
    setAccommodationData((prevData) => {
      return prevData.map((item) => ({
        ...item,
        number_of_rooms: numberOfRoomsRequired,
      }));
    });
  }, [numberOfRoomsRequired]);
  function getUniqueEmailsFromArray(x, y, z) {
    // Step 1: Flatten the arrays to get a single array containing all the objects
    const combinedArray = [...x, { data: { email: y } }, ...z];

    // Step 2: Extract the 'name' property from each object
    const namesArray = combinedArray?.map((item) => item.data.email);

    // Step 3: Use a Set to store the unique names
    const uniqueNamesSet = new Set(namesArray);

    // Step 4: Convert the Set back to an array to get the unique names
    const uniqueNames = Array.from(uniqueNamesSet);

    return uniqueNames;
  }
  function getUniqueNamesFromArray(x, y, z) {
    // Step 1: Combine the arrays and filter out any undefined values
    const combinedArray = [
      ...(x || []),
      { data: { name: y } },
      ...(z || []),
    ].filter(Boolean);

    // Step 2: Extract the 'name' property from each object
    const namesArray = combinedArray.map((item) => item.data.name);

    // Step 3: Use a Set to store the unique names
    const uniqueNamesSet = new Set(namesArray);

    // Step 4: Convert the Set back to an array to get the unique names
    const uniqueNames = Array.from(uniqueNamesSet);

    return uniqueNames;
  }
  const uniqueEmails = getUniqueEmailsFromArray(
    travellersData,
    LocalStorageData?.email,
    roomsData
  );
  const uniqueNames = getUniqueNamesFromArray(
    travellersData,
    LocalStorageData?.owner_name,
    roomsData
  );

  //delete Roooms Row
  const handleRoomsDeleteRow = (id) => {
    const updatedRows = roomsData.filter((row) => row.id !== id);
    setRoomsData(updatedRows);
  };

  const validateError = (event) => {
    // console.log("Event", event);
    let basicDetailsDataObj = {
      billable: basicDetails?.billable !== "" ? true : false,
      client_id: basicDetails?.client_id !== "" ? true : false,
      project_id: basicDetails?.project_id !== "" ? true : false,
      reason_for_travel: basicDetails?.reason_for_travel !== "" ? true : false,
    };

    setValidateForBadicDetailsDataRow(basicDetailsDataObj);
    let travellerDatalist = [];
    let validationTravellerArrayForTraveller = [];
    travellersData.map((item, index) => {
      // if (!validationTravellerArray.includes(item?.data?.emp_id)) {
      validationTravellerArrayForTraveller.push(item?.data?.emp_id);
      // }
      travellerDatalist = [
        ...travellerDatalist,
        {
          data: {
            ...item.data,
            emp_id:
              (item.data.emp_id === undefined || item.data.emp_id === "") &&
              item.data?.is_employee === "Yes" &&
              treavellerRadioButton
                ? true
                : false,

            validationForCheckingDuplicateValue:
              validationTravellerArrayForTraveller.filter(
                (element) => element === item.data.emp_id
              ).length > 1
                ? true
                : false,

            empIdPattern:
              basicDetails?.booking_for === "others"
                ? (item.data.emp_id === LocalStorageData?.emp_id) === true
                  ? true
                  : false
                : false,
            name:
              (item.data.name === undefined || item.data.name === "") &&
              item.data?.is_employee === "No" &&
              treavellerRadioButton
                ? true
                : false,
            // preferred_time:
            //   (item.data.preferred_time === undefined ||
            //     item.data.preferred_time === "") &&
            // treavellerRadioButton
            //   ? true
            //   : false,
            namePattern:
              item.data?.is_employee === "No"
                ? /^[A-Za-z]+$/.test(item.data.name) === true
                  ? false
                  : true
                : false,
            gender:
              (item.data.gender === undefined || item.data.gender === "") &&
              item.data?.is_employee === "No" &&
              treavellerRadioButton
                ? true
                : false,
            phone:
              (item.data.phone === undefined || item.data.phone === "") &&
              item.data?.is_employee === "No"
                ? true
                : false,
            phonePattern:
              item.data?.is_employee === "No"
                ? /^[0-9]{10,10}$/.test(item.data.phone) === true
                  ? false
                  : true
                : false,
            email:
              (item.data.email === undefined || item.data.email === "") &&
              item.data?.is_employee === "No" &&
              treavellerRadioButton
                ? true
                : false,
            emailPattern:
              item.data?.is_employee === "No"
                ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    item.data.email
                  ) === true
                  ? false
                  : true
                : false,
            dob:
              (item.data.dob === undefined || item.data.dob === "") &&
              item.data?.is_employee === "No" &&
              treavellerRadioButton
                ? true
                : false,
          },
        },
      ];

      return item;
    });

    setValidateForTravellersDataRow(travellerDatalist);
    let travelDatalist = [];
    rows.map((item, index) => {
      travelDatalist = [
        ...travelDatalist,
        {
          data: {
            ...item.data,
            travel_mode:
              (item.data.travel_mode === undefined ||
                item.data.travel_mode === "") &&
              treavellerRadioButton
                ? true
                : false,
            travel_from_city:
              (item.data.travel_from_city === undefined ||
                item.data.travel_from_city === null) &&
              treavellerRadioButton
                ? true
                : false,
            travel_to_city:
              (item.data.travel_to_city === undefined ||
                item.data.travel_to_city === null) &&
              treavellerRadioButton
                ? true
                : false,
            departure:
              (item.data.departure === undefined ||
                item.data.departure === "") &&
              treavellerRadioButton
                ? true
                : false,
            return:
              (item.data.return === undefined || item.data.return === "") &&
              item?.data?.trip_type === "Return" &&
              treavellerRadioButton
                ? true
                : false,
          },
        },
      ];

      return item;
    });
    setValidateForTravelDataRow(travelDatalist);
    let accommodationDatalist = [];
    accommodationData?.map((item, index) => {
      accommodationDatalist = [
        ...accommodationDatalist,
        {
          data: {
            ...item.data,
            city:
              (item.data.city === undefined || item.data.city === null) &&
              accommodationRadioButton
                ? true
                : false,
            preferred_location:
              (item.data.preferred_location === undefined ||
                item.data.preferred_location === null) &&
              accommodationRadioButton
                ? true
                : false,
            checkIn:
              (item.data.checkIn === undefined || item.data.checkIn === "") &&
              accommodationRadioButton
                ? true
                : false,
            checkOut:
              (item.data.checkOut === undefined || item.data.checkOut === "") &&
              accommodationRadioButton
                ? true
                : false,
            breakfastRequired:
              (item.data.breakfastRequired === undefined ||
                item.data.breakfastRequired === "") &&
              accommodationRadioButton
                ? true
                : false,
          },
        },
      ];

      return item;
    });
    setValidateForAccommodationDataRow(accommodationDatalist);
    let occupancyDatalist = [];
    let validationTravellerArrayForOccupancy = [];

    roomsData?.map((item, index) => {
      validationTravellerArrayForOccupancy.push(item?.data?.emp_id);
      occupancyDatalist = [
        ...occupancyDatalist,
        {
          data: {
            ...item.data,
            emp_id:
              (item.data.emp_id === undefined || item.data.emp_id === "") &&
              item.data?.is_employee === "Yes" &&
              accommodationRadioButton
                ? true
                : false,
            empIdPattern:
              basicDetails?.booking_for === "others"
                ? (item.data.emp_id === LocalStorageData?.emp_id) === true
                  ? true
                  : false
                : false,
            validationForCheckingDuplicateValue:
              validationTravellerArrayForOccupancy.filter(
                (element) => element === item.data.emp_id
              ).length > 1
                ? true
                : false,

            name:
              (item.data.name === undefined || item.data.name === "") &&
              item.data?.is_employee === "No" &&
              accommodationRadioButton
                ? true
                : false,
            namePattern:
              item.data?.is_employee === "No"
                ? /^[A-Za-z]+$/.test(item.data.name) === true
                  ? false
                  : true
                : false,
            gender:
              (item.data.gender === undefined || item.data.gender === "") &&
              item.data?.is_employee === "No" &&
              accommodationRadioButton
                ? true
                : false,
            phone:
              (item.data.phone === undefined || item.data.phone === "") &&
              item.data?.is_employee === "No" &&
              accommodationRadioButton
                ? true
                : false,
            phonePattern:
              item.data?.is_employee === "No"
                ? /^[0-9]{10,10}$/.test(item.data.phone) === true
                  ? false
                  : true
                : false,

            email:
              (item.data.email === undefined || item.data.email === "") &&
              item.data?.is_employee === "No" &&
              accommodationRadioButton
                ? true
                : false,
            emailPattern:
              item.data?.is_employee === "No"
                ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(
                    item.data.email
                  ) === true
                  ? false
                  : true
                : false,

            dob:
              (item.data.dob === undefined || item.data.dob === "") &&
              item.data?.is_employee === "No" &&
              accommodationRadioButton
                ? true
                : false,
          },
        },
      ];

      return item;
    });

    setValidateForOccupancyDataRow(occupancyDatalist);

    const basicDetailsData = Object?.keys(basicDetailsDataObj).every(
      (property) => {
        return (
          basicDetailsDataObj.hasOwnProperty(property) &&
          basicDetailsDataObj[property] === true
        );
      }
    );
    const valdateBookingRoomsLimit =
      accommodationData[0]?.number_of_rooms > roomsData?.length ? false : true;

    const hh = [];
    travellersData?.map((val) => hh.push(val?.data?.emp_id?.value));
    roomsData?.map((val) => hh.push(val?.data?.emp_id?.value));

    function hasSpecificStrings(arr) {
      let hasEmpID = false;
      let hasOtherStrings = false;
      let hasOtherStringsdd = true;
      for (let i = 0; i < arr.length; i++) {
        if (arr[i] === LocalStorageData?.emp_id) {
          hasEmpID = true;
        }
        if (arr[i] !== LocalStorageData?.emp_id) {
          hasOtherStrings = true;
        }

        if (
          eval(treavellerRadioButton ? travellersData?.length : 0) +
            eval(accommodationRadioButton ? roomsData?.length : 0) <
          2
        ) {
          hasOtherStringsdd = false;
        }
      }
      // console.log("ffffffff", hasEmpID, hasOtherStrings, hasOtherStringsdd);
      return hasEmpID && hasOtherStrings && hasOtherStringsdd;
    }

    // const validateForMyself_Other = hasSpecificStrings(hh);
    const valdateBookingForMySelf_Other =
      basicDetails?.booking_for === "myself_others"
        ? hasSpecificStrings(hh) === false
          ? true
          : false
        : false;

    let travellersDataRow = travellerDatalist.filter(
      (y) =>
        y.data.emp_id == true ||
        y.data.empIdPattern == true ||
        y.data.validationForCheckingDuplicateValue == true ||
        y.data.name == true ||
        y.data.namePattern == true ||
        y.data.gender == true ||
        y.data.phone == true ||
        y.data.phonePattern == true ||
        y.data.email == true ||
        y.data.emailPattern == true ||
        y.data.dob == true
      // ||        y.data.preferred_time == true
    );
    let travelDataRow = travelDatalist.filter(
      (y) =>
        y.data.travel_mode == true ||
        y.data.travel_from_city == true ||
        y.data.travel_to_city == true ||
        y.data.departure == true ||
        y.data.return == true
    );
    let accommodationDataRow = accommodationDatalist.filter(
      (y) =>
        y.data.city == true ||
        y.data.preferred_location == true ||
        y.data.checkIn == true ||
        y.data.checkOut == true ||
        y.data.breakfastRequired == true
    );
    let occpancyDataRow = occupancyDatalist.filter(
      (y) =>
        y.data.emp_id == true ||
        y.data.empIdPattern == true ||
        y.data.validationForCheckingDuplicateValue == true ||
        y.data.name == true ||
        y.data.namePattern == true ||
        y.data.gender == true ||
        y.data.phone == true ||
        y.data.email == true ||
        y.data.phonePattern == true ||
        y.data.emailPattern == true ||
        y.data.dob == true
    );
    // console.log("travellersDataRoweweweww", travellersDataRow);

    if (
      travellersDataRow.length > 0 ||
      travelDataRow.length > 0 ||
      accommodationDataRow.length > 0 ||
      occpancyDataRow.length > 0 ||
      !basicDetailsData

      // || valdateBookingForMySelf_Other
    ) {
      setError(true);
      event && alert.error("Error in some fields");
    } else {
      if (!treavellerRadioButton && !accommodationRadioButton) {
        event && alert.error("Atleast one booking request is required");
      } else if (rows?.length === 0) {
        event && alert.error("Please add atleast one travel request");
      } else if (valdateBookingForMySelf_Other) {
        event && alert.error("Please add self and one or more other employees");
      } else if (roomsData?.length < accommodationData[0].number_of_rooms) {
        event && alert.error("Number of rooms greater than number of people");
      } else if (roomsData?.length > 2 * accommodationData[0].number_of_rooms) {
        event &&
          alert.error(
            "Number of People in Room can't be greater than twice the number of rooms"
          );
      } else {
        setError(false);
        event && handleFormSubmit();
      }
    }
  };
  useEffect(() => {
    if (error) {
      validateError(false);
    }
  }, [
    //validateForBadicDetailsDataRow,
    basicDetails?.billable,
    basicDetails?.project_id,
    basicDetails?.client_id,
    basicDetails?.reason_for_travel,
    travellersData,
    rows,
    accommodationData,
    roomsData,
  ]);
  // ================================================================================================================
  // useEffect(() => {
  //   async function fetchData() {
  //     if (error) {
  //       setCheckError(true);
  //       // console.log("error", error);
  //     }
  //   }
  //   fetchData();
  // }, [error]);
  const handleFormSubmit = async (value) => {
    // Create constants to store the data for both POST requests
    const requestData1 = {
      basicDetails,
      email: uniqueEmails,
      names: uniqueNames,
      rows: treavellerRadioButton ? rows : [],
      travellersData: treavellerRadioButton ? travellersData : [],
      accommodationData: accommodationRadioButton ? accommodationData : [],
      roomsData: accommodationRadioButton ? roomsData : [],
      management_approval:
        LocalStorageData?.zoho_role === "Management" ? "Approved" : "Pending",
      created_by: LocalStorageData?.email,
      created_by_emp_id: LocalStorageData?.emp_id,
    };
    // Make the first POST request using the postRequest function
    const response1 = await postRequest(
      "/all_travel_request_data",
      requestData1
    );
    console.log("response, ", response1);

    if (response1.success) {
      if (LocalStorageData?.zoho_role === "Management") {
        // const emailData = {
        //   requestId: requestId,
        //   status: "Approved",
        //   user: uniqueNames,
        //   email: uniqueEmails,
        //   ...updateData,
        // };
        const emailData = {
          management_approval: "Approved",
          request_id: response1?.data?.data?._id,
          status: "Approved",
          requested_by: LocalStorageData?.owner_name,
          user: uniqueNames,
          names: uniqueNames,
          email: uniqueEmails,
          basicDetails: response1?.data?.data?.basicDetails,
          rows: treavellerRadioButton ? response1?.data?.data?.rows : [],
          travellersData: treavellerRadioButton
            ? response1?.data?.data?.travellersData
            : [],
          accommodationData: accommodationRadioButton
            ? response1?.data?.data?.accommodationData
            : [],
        };

        const emailResponse = await postRequest(`/status_email`, emailData);

        if (emailResponse.success) {
          alert.show(`Created Successfully`);
          navigate("/alltravelrequest");
        } else {
          // Handle email post request failure here
          console.error(`Email post request failed: ${emailResponse.message}`);
        }
      } else {
        const requestData2 = {
          // requestId: response1?.data?.data?.request_id,
          request_id: response1?.data?.data?._id,
          requestId: response1?.data?.data?.request_id,
          requested_by: LocalStorageData?.owner_name,
          user: uniqueNames,
          names: uniqueNames,
          email: uniqueEmails,
          basicDetails: response1?.data?.data?.basicDetails,
          rows: treavellerRadioButton ? response1?.data?.data?.rows : [],
          travellersData: treavellerRadioButton
            ? response1?.data?.data?.travellersData
            : [],
          accommodationData: accommodationRadioButton
            ? response1?.data?.data?.accommodationData
            : [],
        };
        // // Make the second POST request using the postRequest function
        const emailResponse = await postRequest("/email", requestData2);
        if (emailResponse.success) {
          alert.show(`Created Successfully`);
          navigate("/alltravelRequest");
        } else {
          // Handle email post request failure here
          console.error(`Email post request failed: ${emailResponse.message}`);
        }
      }
    }
  };
  // console.log("rows[0]?.data?.departure",new Date( accommodationData[0]?.data?.checkIn).toLocaleDateString('en-GB'),new Date(rows[0]?.data?.departure).toLocaleDateString('en-GB') );
  // const data = accommodationData?.data?.number_of_rooms;

  // =========Current Date==========
  function getCurrentDate() {
    const today = new Date();
    let month = String(today.getMonth() + 1);
    let day = String(today.getDate());
    const year = String(today.getFullYear());

    // Add leading zeros if month or day is a single digit
    if (month.length < 2) {
      month = "0" + month;
    }
    if (day.length < 2) {
      day = "0" + day;
    }

    return `${year}-${month}-${day}`;
  }

  // =====================filtering city data =============

  function filterObjectByLabel(cityData, data) {
    return cityData.filter((item) => item.value !== data);
  }
  // =================Format date For Inputs===============

  // let d1 = new Date().toISOString().split("T")[0];
  // console.log("validateForBadicDetailsDataRow", validateForBadicDetailsDataRow);
  // const [startDate, setStartDate] = useState(new Date());

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, disabled, validate }, ref) => (
      <button
        type="button"
        // className="form-control btn btn-sm form-control-sm"
        className={classNames("form-control btn btn-sm form-control-sm", {
          "is-invalid": validate,
        })}
        style={{ minHeight: "2.4rem", display: "inline" }}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
      >
        {value === "" ? "dd/mm/yyyy" : value}
      </button>
      // console.log("value",value === "" ?"Yes":"No")
    )
  );

  // console.log("defaultValue", defaultValue);

  const toggleDropdown = (id) => {
    setAccommodationData((prevData) =>
      prevData.map((row) =>
        row.id === id ? { ...row, isOpen: !row.isOpen } : row
      )
    );
  };
  //Handle Reset
  const handleReset = () => {
    setTreavellerRadioButton(false);
    setAccommodationRadioButton(false);
    setBasicDetails({
      booking_for: "self",
      billable: "",
      client_id: "",
      project_id: "",
      reason_for_travel: "",
      special_request: "",
    });
  };
  const handleCancel = () => {
    navigate("/dashboard");
  };
  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <Sidebar />
          <div className="main-panel w-100">
            <div
              className="content-wrapper bg-light w-100"
              style={{
                display: "flex",
                borderRadius: "20px",
                margin: "2rem",
                background: "#6d4199",
                fontSize: "20px",
                fontWeight: "600",
                border: "none",
                width: "100%",
              }}
            >
              {loading && (
                <div class="loader-container">
                  <div class="loader">
                    <img
                      class="logo"
                      src="/assets/images/aceAppsLogo.png"
                      alt="Logo"
                    />
                  </div>
                </div>
              )}

              <div className="row fs-6 w-100">
                <div class=" grid-margin stretch-card w-100">
                  <div class="card" style={{ borderRadius: "20px" }}>
                    <div
                      class="card-body 
                    "
                    >
                      <div className="d-flex justify-content-between align-items-center">
                        <span class="card-description">
                          TRAVEL REQUEST FORM
                        </span>
                      </div>
                      <form className="travel_form" action="">
                        <div className="row">
                          <div className="col-12 col-lg-2">
                            <div className="form-group">
                              <label className="Travel_form_label">
                                Booking For
                              </label>

                              <select
                                className="form-select form-select-sm"
                                value={basicDetails?.booking_for}
                                onChange={inputEvent}
                                name="booking_for"
                              >
                                <option value="" selected disabled>
                                  Select...
                                </option>
                                <option value="self">Self</option>
                                <option value="others">Others</option>
                                <option value="myself_others">
                                  Myself + Others
                                </option>
                              </select>
                            </div>
                          </div>
                          <div className="col-12 col-lg-2">
                            <div className="form-group">
                              <label>Billable</label>
                              <span className="astik"> *</span>
                              <select
                                className={classNames(
                                  "form-select form-select-sm",
                                  {
                                    "is-invalid":
                                      !validateForBadicDetailsDataRow?.billable &&
                                      validateForBadicDetailsDataRow?.billable !==
                                        undefined
                                        ? true
                                        : false,
                                  }
                                )}
                                value={basicDetails?.billable}
                                onChange={inputEvent}
                                name="billable"
                              >
                                <option value="">Select...</option>
                                <option value="Yes">Yes</option>
                                <option value="No">No</option>
                              </select>

                              <small className="isValidate">
                                {!validateForBadicDetailsDataRow?.billable &&
                                  validateForBadicDetailsDataRow?.billable !==
                                    undefined &&
                                  "This field is required"}
                              </small>
                            </div>
                          </div>

                          <div className="col-12 col-lg-3">
                            <div className="form-group">
                              <label>Client Name</label>
                              <span className="astik"> *</span>
                              <select
                                className={classNames(
                                  "form-select form-select-sm",
                                  {
                                    "is-invalid":
                                      !validateForBadicDetailsDataRow?.client_id &&
                                      validateForBadicDetailsDataRow?.client_id !==
                                        undefined
                                        ? true
                                        : false,
                                  }
                                )}
                                value={basicDetails?.client_id}
                                name="client_id"
                                onChange={handleClientChange}
                              >
                                <option value="">Select Client</option>
                                {projects?.map((project) => (
                                  <option
                                    key={project?._id}
                                    value={project?.client_name}
                                  >
                                    {project?.client_name}
                                  </option>
                                ))}
                              </select>
                              <small className="isValidate">
                                {!validateForBadicDetailsDataRow?.client_id &&
                                  validateForBadicDetailsDataRow?.client_id !==
                                    undefined &&
                                  "This field is required"}
                              </small>
                            </div>
                          </div>
                          <div className="col-12 col-lg-3">
                            <div className="form-group">
                              <label>Project Id</label>
                              <span className="astik"> *</span>
                              <select
                                className={classNames(
                                  "form-select form-select-sm",
                                  {
                                    "is-invalid":
                                      !validateForBadicDetailsDataRow?.project_id &&
                                      validateForBadicDetailsDataRow?.project_id !==
                                        undefined
                                        ? true
                                        : false,
                                  }
                                )}
                                value={basicDetails?.project_id}
                                onChange={inputEvent}
                                name="project_id"
                              >
                                <option value="">Select Project</option>
                                {filteredProjectIds.map((project) => (
                                  <option
                                    key={project?._id}
                                    value={project?.project_code}
                                  >
                                    {`${project?.project_code}- ${project?.Description}`}
                                  </option>
                                ))}
                              </select>
                              <small className="isValidate">
                                {!validateForBadicDetailsDataRow?.project_id &&
                                  validateForBadicDetailsDataRow?.project_id !==
                                    undefined &&
                                  "This field is required"}
                              </small>
                            </div>
                          </div>

                          <div className="col-12 col-lg-2">
                            <div className="form-group">
                              <label>Travel Reason</label>
                              <span className="astik"> *</span>
                              <select
                                value={basicDetails?.reason_for_travel}
                                name="reason_for_travel"
                                className={classNames(
                                  "form-select form-select-sm",
                                  {
                                    "is-invalid":
                                      !validateForBadicDetailsDataRow?.reason_for_travel &&
                                      validateForBadicDetailsDataRow?.reason_for_travel !==
                                        undefined
                                        ? true
                                        : false,
                                  }
                                )}
                                onChange={inputEvent}
                                placeholder="Enter Reason for Travel"
                              >
                                <option value="">Select...</option>
                                <option value="Sales">Sales</option>
                                <option value="Client Visit">
                                  Client Visit
                                </option>
                                <option value="Consulting">Consulting</option>
                                <option value="Off-Site">Off-Site</option>
                              </select>
                              <small className="isValidate">
                                {!validateForBadicDetailsDataRow?.reason_for_travel &&
                                  validateForBadicDetailsDataRow?.reason_for_travel !==
                                    undefined &&
                                  "This field is required"}
                              </small>
                            </div>
                          </div>
                        </div>
                        {/* ===============================Travellers==================== */}
                        <div
                          className="travelFormResponsive"
                          style={{
                            fontSize: "0.1rem",
                            border: "1px solid lightgrey",
                            background: treavellerRadioButton
                              ? "#fff"
                              : "#d3d3d324",
                            borderRadius: "10px",
                          }}
                        >
                          <div className="form-group w-100 d-flex justify-content-start align-items-center m-0 p-0">
                            <label className="col-12 col-lg-3 d-flex align-items-center col-form-label">
                              <i
                                className="mdi mdi-airplane-takeoff fs-3  mx-3"
                                style={{ color: "#d14124" }}
                              />
                              Transport Required
                            </label>

                            <div className="col-12 col-lg-3">
                              <div className="form-check form-check-info">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-control form-control-sm"
                                    value={treavellerRadioButton}
                                    checked={
                                      treavellerRadioButton === true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      return setTreavellerRadioButton(
                                        !treavellerRadioButton
                                      );
                                    }}
                                    name="travellers_required"
                                  />
                                  Yes <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-3">
                              <div className="form-check form-check-info">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-control form-control-sm"
                                    value={treavellerRadioButton}
                                    checked={
                                      treavellerRadioButton === false
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      return setTreavellerRadioButton(
                                        !treavellerRadioButton
                                      );
                                    }}
                                    name="travellers_required"
                                  />
                                  No <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                          </div>

                          {treavellerRadioButton && (
                            <div
                              style={{
                                padding: "1rem",
                              }}
                            >
                              <div className="d-flex justify-content-between ">
                                <h6 className="" style={{ color: "#d03e20" }}>
                                  Travellers
                                </h6>

                                {basicDetails?.booking_for === "self" ? (
                                  ""
                                ) : (
                                  <div
                                    id="add_traveller"
                                    class="Btn my-2"
                                    onClick={handleAddTraveller}
                                  >
                                    <div class="sign">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                        fill-rule="evenodd"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                )}
                              </div>

                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Emp </th>
                                    <th style={{ width: "15%" }}>
                                      Emp ID
                                      <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Full Name{" "}
                                      <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Gender <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Phone <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Email <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      DOB <span className="astik"> *</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {travellersData?.map((traveller, index) => (
                                    <tr key={traveller.id}>
                                      <td>
                                        <div class="form-check form-check-info">
                                          <label class="form-check-label">
                                            <input
                                              type="checkbox"
                                              class="form-check-input"
                                              checked={
                                                traveller?.data?.is_employee ===
                                                "Yes"
                                                  ? true
                                                  : false
                                              }
                                              disabled={
                                                basicDetails?.booking_for ===
                                                "self"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                handleTravellerChange(
                                                  traveller.id,
                                                  {
                                                    name: "",
                                                    emp_id: "",
                                                    email: "",
                                                    phone: "",
                                                    dob: new Date(),
                                                    gender: "",
                                                    is_employee: e.target
                                                      .checked
                                                      ? "Yes"
                                                      : "No",
                                                  }
                                                )
                                              }
                                            />
                                            <i class="input-helper"></i>
                                          </label>
                                        </div>
                                      </td>
                                      <td>
                                        <Select
                                          className={classNames(" ", {
                                            "is-invalid":
                                              validateForTravellersDataRow[
                                                index
                                              ]?.data?.emp_id ||
                                              (traveller?.data?.is_employee ===
                                                "Yes" &&
                                                !validateForTravellersDataRow[
                                                  index
                                                ]?.data?.emp_id &&
                                                validateForTravellersDataRow[
                                                  index
                                                ]?.data?.empIdPattern),
                                          })}
                                          name="emp_id"
                                          options={getEmployeeDataByStatusCode.map(
                                            (val) => {
                                              return {
                                                ...val,
                                                isDisabled: travellersData
                                                  ?.map((item) => {
                                                    return item?.data?.emp_id
                                                      ?.value;
                                                  })
                                                  .includes(val?.value),
                                              };
                                            }
                                          )}
                                          defaultValue={
                                            getEmployeeDefaultByStatusCode[0]
                                          }
                                          value={
                                            traveller?.data?.is_employee ===
                                            "Yes"
                                              ? traveller?.data?.emp_id
                                              : ""
                                          }
                                          onChange={(selectedOption) =>
                                            handleTravellerChange(
                                              traveller.id,
                                              {
                                                ...traveller.data,
                                                emp_id: selectedOption,
                                              }
                                            )
                                          }
                                          isDisabled={
                                            traveller?.data?.is_employee ===
                                              "Yes" &&
                                            basicDetails?.booking_for !== "self"
                                              ? false
                                              : true
                                          }
                                        />

                                        <small className="isValidate">
                                          {validateForTravellersDataRow[index]
                                            ?.data?.emp_id &&
                                            "This field is required"}
                                          {traveller?.data?.is_employee ===
                                            "Yes" &&
                                            !validateForTravellersDataRow[index]
                                              ?.data?.emp_id &&
                                            validateForTravellersDataRow[index]
                                              ?.data?.empIdPattern &&
                                            "Please select others"}
                                          {traveller?.data?.is_employee ===
                                            "Yes" &&
                                            !validateForTravellersDataRow[index]
                                              ?.data?.emp_id &&
                                            validateForTravellersDataRow[index]
                                              ?.data
                                              ?.validationForCheckingDuplicateValue &&
                                            "Same Employee Id not allowed"}
                                        </small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          value={traveller?.data?.name}
                                          name="name"
                                          className={classNames(
                                            " form-control  form-control-sm ",
                                            {
                                              "is-invalid":
                                                validateForTravellersDataRow[
                                                  index
                                                ]?.data?.name,
                                            }
                                          )}
                                          disabled={
                                            traveller?.data?.is_employee ===
                                            "Yes"
                                              ? true
                                              : false
                                          }
                                          onChange={(e) =>
                                            handleTravellerChange(
                                              traveller.id,
                                              {
                                                ...traveller.data,

                                                name: e.target.value,
                                              }
                                            )
                                          }
                                          placeholder="Enter Full Name"
                                        />
                                        <small className="isValidate">
                                          {validateForTravellersDataRow[index]
                                            ?.data?.name &&
                                            "This field is required"}
                                          {traveller?.data?.is_employee ===
                                            "No" &&
                                            !validateForTravellersDataRow[index]
                                              ?.data?.name &&
                                            validateForTravellersDataRow[index]
                                              ?.data?.namePattern &&
                                            "Fullname is not valid"}
                                        </small>
                                      </td>
                                      <td style={{ width: "10%" }}>
                                        <select
                                          className={classNames(
                                            "form-select form-select-md",
                                            {
                                              "is-invalid":
                                                validateForTravellersDataRow[
                                                  index
                                                ]?.data?.gender,
                                            }
                                          )}
                                          disabled={
                                            traveller?.data?.is_employee ===
                                            "Yes"
                                              ? true
                                              : false
                                          }
                                          value={traveller?.data?.gender}
                                          placeholder="Select"
                                          onChange={(e) =>
                                            handleTravellerChange(
                                              traveller.id,
                                              {
                                                ...traveller?.data,
                                                gender: e.target.value,
                                              }
                                            )
                                          }
                                        >
                                          <option value="" selected disabled>
                                            Select...
                                          </option>
                                          <option value="Male">Male</option>
                                          <option value="Female">Female</option>
                                          <option value="Others">Others</option>
                                        </select>
                                        <small className="isValidate">
                                          {validateForTravellersDataRow[index]
                                            ?.data?.gender &&
                                            "This field is required"}
                                        </small>
                                      </td>
                                      <td style={{ width: "12%" }}>
                                        <input
                                          type={"text"}
                                          disabled={
                                            traveller?.data?.is_employee ===
                                            "Yes"
                                              ? true
                                              : false
                                          }
                                          value={traveller?.data?.phone}
                                          name="phone"
                                          className={classNames(
                                            "form-control form-control-sm",
                                            {
                                              "is-invalid":
                                                validateForTravellersDataRow[
                                                  index
                                                ]?.data?.phone,
                                            }
                                          )}
                                          placeholder="Enter Phone"
                                          onChange={(e) => {
                                            let { value } = e.target;

                                            // Limit the number of digits to 6
                                            if (value.length > 10) {
                                              value = value.slice(0, 10);
                                            }

                                            handleTravellerChange(
                                              traveller.id,
                                              {
                                                ...traveller?.data,

                                                phone: value,
                                              }
                                            );
                                          }}
                                        />
                                        <small className="isValidate">
                                          {validateForTravellersDataRow[index]
                                            ?.data?.phone &&
                                            "This field is required"}
                                          {traveller?.data?.is_employee ===
                                            "No" &&
                                            !validateForTravellersDataRow[index]
                                              ?.data?.phone &&
                                            validateForTravellersDataRow[index]
                                              ?.data?.phonePattern &&
                                            "Phone no is not valid"}
                                        </small>
                                      </td>
                                      <td>
                                        <input
                                          // required
                                          type="email"
                                          disabled={
                                            traveller?.data?.is_employee ===
                                            "Yes"
                                              ? true
                                              : false
                                          }
                                          value={traveller?.data?.email}
                                          name="email"
                                          className={classNames(
                                            "form-control form-control-sm",
                                            {
                                              "is-invalid":
                                                validateForTravellersDataRow[
                                                  index
                                                ]?.data?.email,
                                            }
                                          )}
                                          placeholder="Enter Email"
                                          onChange={(e) =>
                                            handleTravellerChange(
                                              traveller.id,
                                              {
                                                ...traveller?.data,

                                                email: e.target.value,
                                              }
                                            )
                                          }
                                        />
                                        <small className="isValidate">
                                          {validateForTravellersDataRow[index]
                                            ?.data?.email &&
                                            "This field is required"}
                                          {traveller?.data?.is_employee ===
                                            "No" &&
                                            !validateForTravellersDataRow[index]
                                              ?.data?.email &&
                                            validateForTravellersDataRow[index]
                                              ?.data?.emailPattern &&
                                            "Email ID is not valid"}
                                        </small>
                                      </td>
                                      <td>
                                        <DatePicker
                                          dateFormat="dd/MM/yyyy"
                                          maxDate={new Date()}
                                          peekNextMonth
                                          className="formInput"
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          showWeekNumbers
                                          selected={
                                            new Date(traveller?.data?.dob)
                                          }
                                          onChange={(e) =>
                                            handleTravellerChange(
                                              traveller.id,
                                              {
                                                ...traveller?.data,
                                                dob: e,
                                              }
                                            )
                                          }
                                          disabled={
                                            traveller?.data?.is_employee ===
                                            "Yes"
                                              ? true
                                              : false
                                          }
                                          customInput={
                                            <ExampleCustomInput
                                              disabled={
                                                traveller?.data?.is_employee ===
                                                "Yes"
                                                  ? true
                                                  : false
                                              }
                                              validate={
                                                validateForTravellersDataRow[
                                                  index
                                                ]?.data?.dob
                                              }
                                            />
                                          }
                                        />

                                        <br />
                                        <small className="isValidate">
                                          {validateForTravellersDataRow[index]
                                            ?.data?.dob &&
                                            "This field is required"}
                                        </small>
                                      </td>

                                      {basicDetails?.booking_for !== "self" && (
                                        <td>
                                          <div
                                            id="Delete_Traveller"
                                            class="Btn my-2"
                                            onClick={() =>
                                              handleTravellerDeleteRow(
                                                traveller.id
                                              )
                                            }
                                          >
                                            <div class="sign">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30 30"
                                                width="30px"
                                                height="30px"
                                              >
                                                <path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z" />
                                              </svg>
                                            </div>
                                          </div>
                                        </td>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>

                              {/* ===================================Travel============================ */}
                              <div className="mt-4">
                                <div className="d-flex justify-content-between  ">
                                  <h6 className="text-primary">Travel</h6>

                                  <div
                                    id="add_travel"
                                    class="Btn my-2"
                                    onClick={handleAddRow}
                                  >
                                    <div class="sign" title="Add Travel">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                        fill-rule="evenodd"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>
                                {/* ===========Radio Box======= */}
                              </div>

                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th style={{ width: "12%" }}>
                                      Mode <span className="astik"> *</span>
                                    </th>
                                    <th style={{ width: "20%" }}>Trip</th>
                                    <th style={{ width: "20%" }}>
                                      From (City)
                                      <span className="astik"> *</span>
                                    </th>
                                    <th style={{ width: "20%" }}>
                                      To (City){" "}
                                      <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Departure{" "}
                                      <span className="astik"> *</span>
                                    </th>

                                    <th colSpan={1}>Return</th>
                                    <th>Preferred Time</th>
                                    <th>Return Preferred Time</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {rows.map((row, index) => (
                                    <tr key={row.id}>
                                      <td>
                                        <select
                                          className={classNames(
                                            "form-select form-select-md",
                                            {
                                              "is-invalid":
                                                validateForTravelDataRow[index]
                                                  ?.data?.travel_mode,
                                            }
                                          )}
                                          value={row.data.travel_mode}
                                          onChange={(e) =>
                                            handleDataChange(row.id, {
                                              ...row.data,
                                              travel_mode: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="" selected disabled>
                                            Select
                                          </option>
                                          {travelMode.map((mode) => (
                                            <option value={mode} key={mode}>
                                              {mode}
                                            </option>
                                          ))}
                                        </select>
                                        <small className="isValidate">
                                          {validateForTravelDataRow[index]?.data
                                            ?.travel_mode &&
                                            "This field is required"}
                                        </small>
                                      </td>
                                      <td>
                                        <select
                                          name="trip_type"
                                          className="form-select form-select-md"
                                          value={row?.data?.trip_type}
                                          onChange={(e) =>
                                            handleDataChange(row.id, {
                                              ...row.data,
                                              trip_type: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="" selected disabled>
                                            Select
                                          </option>
                                          <option value="OneWay">
                                            One-Way
                                          </option>
                                          <option value="Return">Return</option>
                                        </select>
                                      </td>
                                      <td>
                                        <Select
                                          className={classNames("form-select", {
                                            "is-invalid":
                                              validateForTravelDataRow[index]
                                                ?.data?.travel_from_city,
                                          })}
                                          styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              fontSize: "0.7rem !important",
                                            }),
                                          }}
                                          isClearable={true}
                                          name="travel_from_city"
                                          options={cityData}
                                          value={row.data.travel_from_city}
                                          onChange={(selectedOption) =>
                                            handleDataChange(row.id, {
                                              ...row.data,
                                              travel_from_city: selectedOption,
                                            })
                                          }
                                        />
                                        <small className="isValidate">
                                          {validateForTravelDataRow[index]?.data
                                            ?.travel_from_city &&
                                            "This field is required"}
                                        </small>
                                      </td>
                                      <td>
                                        <Select
                                          className={classNames(
                                            "form-select-select",
                                            {
                                              "is-invalid":
                                                validateForTravelDataRow[index]
                                                  ?.data?.travel_to_city,
                                            }
                                          )}
                                          styles={{
                                            control: (baseStyles, state) => ({
                                              ...baseStyles,
                                              fontSize: "0.7rem !important",
                                            }),
                                          }}
                                          isClearable={true}
                                          name="travel_to_city"
                                          options={filterObjectByLabel(
                                            cityData,
                                            row.data.travel_from_city?.label
                                          )}
                                          value={row?.data?.travel_to_city}
                                          onChange={(selectedOption) =>
                                            handleDataChange(row.id, {
                                              ...row.data,
                                              travel_to_city: selectedOption,
                                            })
                                          }
                                        />
                                        <small className="isValidate">
                                          {validateForTravelDataRow[index]?.data
                                            ?.travel_to_city &&
                                            "This field is required"}
                                        </small>
                                      </td>
                                      <td>
                                        <DatePicker
                                          dateFormat="dd/MM/yyyy"
                                          minDate={new Date()}
                                          showWeekNumbers
                                          selected={row?.data?.departure}
                                          onChange={(e) =>
                                            handleDataChange(row.id, {
                                              ...row.data,
                                              departure: e,
                                            })
                                          }
                                          customInput={
                                            <ExampleCustomInput
                                              validate={
                                                validateForTravelDataRow[index]
                                                  ?.data?.departure
                                              }
                                            />
                                          }
                                        />

                                        <br />
                                        <small className="isValidate">
                                          {validateForTravelDataRow[index]?.data
                                            ?.departure &&
                                            "This field is required"}
                                        </small>
                                      </td>
                                      <td>
                                        <DatePicker
                                          dateFormat="dd/MM/yyyy"
                                          minDate={
                                            new Date(row?.data?.departure)
                                          }
                                          showWeekNumbers
                                          selected={
                                            row?.data?.departure ===
                                              undefined ||
                                            row?.data?.trip_type === "OneWay"
                                              ? ""
                                              : row?.data?.return
                                          }
                                          onChange={(e) =>
                                            handleDataChange(row.id, {
                                              ...row.data,
                                              return:
                                                row?.data?.departure ===
                                                  undefined ||
                                                row?.data?.trip_type ===
                                                  "OneWay"
                                                  ? ""
                                                  : e,
                                            })
                                          }
                                          disabled={
                                            row?.data?.departure ===
                                              undefined ||
                                            row?.data?.trip_type === "OneWay"
                                              ? true
                                              : false
                                          }
                                          customInput={
                                            <ExampleCustomInput
                                              disabled={
                                                row.data.trip_type === "OneWay"
                                                  ? true
                                                  : false
                                              }
                                              validate={
                                                validateForTravelDataRow[index]
                                                  ?.data?.return
                                              }
                                            />
                                          }
                                        />

                                        <br />
                                        <small className="isValidate">
                                          {validateForTravelDataRow[index]?.data
                                            ?.return &&
                                            "This field is required"}
                                        </small>
                                      </td>
                                      <td style={{ width: "10%" }}>
                                        <select
                                          className="form-control form-control-sm"
                                          name="preferred_time"
                                          value={row?.data?.preferred_time}
                                          placeholder="Select"
                                          onChange={(e) =>
                                            handleDataChange(row.id, {
                                              ...row.data,
                                              preferred_time: e.target.value,
                                            })
                                          }
                                        >
                                          <option value="" selected>
                                            <small>select...</small>
                                          </option>
                                          <option value="00:00-06:00">
                                            00:00-06:00
                                          </option>
                                          <option value="06:00-12:00">
                                            06:00-12:00
                                          </option>
                                          <option value="12:00-18:00">
                                            12:00-18:00
                                          </option>
                                          <option value="18:00-00:00">
                                            18:00-00:00
                                          </option>
                                        </select>
                                      </td>
                                      <td style={{ width: "10%" }}>
                                        <select
                                          disabled={
                                            row?.data?.departure ===
                                              undefined ||
                                            row?.data?.trip_type === "OneWay"
                                              ? true
                                              : false
                                          }
                                          className="form-control form-control-sm"
                                          name="return_preferred_time"
                                          value={
                                            row?.data?.return_preferred_time
                                          }
                                          placeholder="Select"
                                          onChange={(e) =>
                                            handleDataChange(row.id, {
                                              ...row.data,
                                              return_preferred_time:
                                                e.target.value,
                                            })
                                          }
                                        >
                                          <option value="" selected>
                                            <small>select...</small>
                                          </option>
                                          <option value="00:00-06:00">
                                            00:00-06:00
                                          </option>
                                          <option value="06:00-12:00">
                                            06:00-12:00
                                          </option>
                                          <option value="12:00-18:00">
                                            12:00-18:00
                                          </option>
                                          <option value="18:00-00:00">
                                            18:00-00:00
                                          </option>
                                        </select>
                                        {/* <small className="isValidate">
                                        {validateForTravellersDataRow[index]
                                          ?.data?.preferred_time &&
                                          "This field is required"}
                                      </small> */}
                                      </td>
                                      <td>
                                        <div
                                          id="Delete_Travel"
                                          class="Btn my-2"
                                          onClick={() =>
                                            handleDeleteRow(row.id)
                                          }
                                        >
                                          <div class="sign">
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              viewBox="0 0 30 30"
                                              width="30px"
                                              height="30px"
                                            >
                                              <path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z" />
                                            </svg>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>
                        {/* ===============================Accomendation====================== */}
                        <div
                          className="travelFormResponsive"
                          style={{
                            border: "1px solid lightgrey",
                            marginTop: "1rem",
                            borderRadius: "10px",
                            background: accommodationRadioButton
                              ? "#fff"
                              : "#d3d3d324",
                          }}
                        >
                          <div className="form-group d-flex justify-content-start align-items-center m-0 p-0">
                            <label className="col-lg-3 col-12 d-flex align-items-center col-form-label">
                              <i
                                className="mdi mdi-hotel fs-3 mx-3"
                                style={{ color: "#d14124" }}
                              />
                              Accommodation Required
                            </label>

                            <div className="col-lg-3 col-12">
                              <div className="form-check form-check-info">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-control form-control-md"
                                    value={accommodationRadioButton}
                                    checked={
                                      accommodationRadioButton === true
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      setAccommodationRadioButton(
                                        !accommodationRadioButton
                                      );
                                    }}
                                  />
                                  Yes <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-3">
                              <div className="form-check form-check-info">
                                <label className="form-check-label">
                                  <input
                                    type="radio"
                                    className="form-control form-control-md"
                                    value={accommodationRadioButton}
                                    checked={
                                      accommodationRadioButton === false
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      setAccommodationRadioButton(
                                        !accommodationRadioButton
                                      );
                                    }}
                                  />
                                  No <i className="input-helper"></i>
                                </label>
                              </div>
                            </div>
                          </div>

                          {accommodationRadioButton && (
                            <div className="mt-2" style={{ padding: "1rem" }}>
                              <div className="d-flex justify-content-between">
                                <h6 className="" style={{ color: "#d03e20" }}>
                                  Accommodation
                                </h6>

                                <div className="d-flex">
                                  <div>
                                    <label htmlFor="">Rooms:</label>
                                    <select
                                      type="number"
                                      max={9}
                                      value={numberOfRoomsRequired}
                                      onChange={handleInputRoomChange}
                                      class="form-select  form-select-xs p-2 btn-no-outline-primary bg-light dropdown-toggle"
                                      style={{
                                        padding: "1rem 2.5rem",
                                        width: "5rem",
                                      }}
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                    </select>
                                  </div>
                                  <div
                                    id="add_accommodation"
                                    class="btn my-2"
                                    onClick={handleAddAccommodation}
                                  >
                                    <div class="sign">
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        viewBox="0 0 24 24"
                                        width="24px"
                                        height="24px"
                                        fill-rule="evenodd"
                                      >
                                        <path
                                          fill-rule="evenodd"
                                          d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                                        />
                                      </svg>
                                    </div>
                                  </div>
                                </div>

                                {/* )} */}
                              </div>

                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th style={{ width: "30%" }}>
                                      <span className="astik"> *</span>
                                    </th>
                                    <th style={{ width: "20%" }}>
                                      Preferred Location{" "}
                                      <span className="astik"> *</span>
                                    </th>
                                    <th style={{ width: "20%" }}>
                                      Check-in <span className="astik"> *</span>
                                    </th>
                                    <th style={{ width: "20%" }}>
                                      Check-out{" "}
                                      <span className="astik"> *</span>
                                    </th>
                                    <th style={{ width: "10%" }}>
                                      Breakfast Required
                                      <span className="astik"> *</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {accommodationData?.map(
                                    (accommodation, index) => (
                                      <tr key={accommodation.id}>
                                        <td>
                                          <Select
                                            className={classNames(
                                              "form-select-select",
                                              {
                                                "is-invalid":
                                                  validateForAccommodationDataRow[
                                                    index
                                                  ]?.data?.city,
                                              }
                                            )}
                                            isClearable={true}
                                            name="city"
                                            options={cityData}
                                            value={
                                              accommodationData?.data?.city
                                            }
                                            onChange={(selectedOption) =>
                                              handleAccommodationChange(
                                                accommodation.id,
                                                {
                                                  ...accommodation.data,
                                                  city: selectedOption,
                                                }
                                              )
                                            }
                                          />
                                          <small className="isValidate">
                                            {validateForAccommodationDataRow[
                                              index
                                            ]?.data?.city &&
                                              "This field is required"}
                                          </small>
                                        </td>
                                        <td>
                                          <input
                                            type="text"
                                            value={
                                              accommodation.data
                                                .preferred_location
                                            }
                                            name="preferred_location"
                                            className={classNames(
                                              "form-control form-control-md",
                                              {
                                                "is-invalid":
                                                  validateForAccommodationDataRow[
                                                    index
                                                  ]?.data?.preferred_location,
                                              }
                                            )}
                                            onChange={(e) =>
                                              handleAccommodationChange(
                                                accommodation.id,
                                                {
                                                  preferred_location:
                                                    e.target.value,
                                                }
                                              )
                                            }
                                          />
                                          <small className="isValidate">
                                            {validateForAccommodationDataRow[
                                              index
                                            ]?.data?.preferred_location &&
                                              "This field is required"}
                                          </small>
                                        </td>
                                        <td>
                                          <DatePicker
                                            wrapperClassName="datepicker"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={new Date()}
                                            selected={
                                              accommodation?.data?.checkIn ===
                                              undefined
                                                ? ""
                                                : accommodation?.data?.checkIn
                                            }
                                            onChange={(e) =>
                                              handleAccommodationChange(
                                                accommodation.id,
                                                {
                                                  checkIn: e,
                                                }
                                              )
                                            }
                                            customInput={
                                              <ExampleCustomInput
                                                validate={
                                                  validateForAccommodationDataRow[
                                                    index
                                                  ]?.data?.checkIn
                                                }
                                              />
                                            }
                                          />

                                          <br />
                                          <small className="isValidate">
                                            {validateForAccommodationDataRow[
                                              index
                                            ]?.data?.checkIn &&
                                              "This field is required"}
                                          </small>
                                        </td>
                                        <td>
                                          <DatePicker
                                            wrapperClassName="datepicker"
                                            dateFormat="dd/MM/yyyy"
                                            minDate={
                                              accommodation?.data?.checkIn ===
                                              undefined
                                                ? new Date()
                                                : accommodation?.data?.checkIn
                                            }
                                            showWeekNumbers
                                            selected={
                                              accommodation?.data?.checkOut ===
                                              undefined
                                                ? ""
                                                : accommodation?.data?.checkOut
                                            }
                                            onChange={(e) =>
                                              handleAccommodationChange(
                                                accommodation.id,
                                                {
                                                  checkOut: e,
                                                }
                                              )
                                            }
                                            customInput={
                                              <ExampleCustomInput
                                                validate={
                                                  validateForAccommodationDataRow[
                                                    index
                                                  ]?.data?.checkOut
                                                }
                                              />
                                            }
                                          />

                                          <br />
                                          <small className="isValidate">
                                            {validateForAccommodationDataRow[
                                              index
                                            ]?.data?.checkOut &&
                                              "This field is required"}
                                          </small>
                                        </td>
                                        <td>
                                          <select
                                            name="breakfastRequired"
                                            className={classNames(
                                              "form-select form-select-md",
                                              {
                                                "is-invalid":
                                                  validateForAccommodationDataRow[
                                                    index
                                                  ]?.data?.breakfastRequired,
                                              }
                                            )}
                                            value={
                                              accommodation.data
                                                .breakfastRequired
                                            }
                                            onChange={(e) =>
                                              handleAccommodationChange(
                                                accommodation.id,
                                                {
                                                  breakfastRequired:
                                                    e.target.value,
                                                }
                                              )
                                            }
                                          >
                                            <option value="" selected disabled>
                                              Select
                                            </option>
                                            <option value="Yes">Yes</option>
                                            <option value="No">No</option>
                                          </select>
                                          <small className="isValidate">
                                            {validateForAccommodationDataRow[
                                              index
                                            ]?.data?.breakfastRequired &&
                                              "This field is required"}
                                          </small>
                                        </td>

                                        <td>
                                          {index === 0 ? (
                                            ""
                                          ) : (
                                            <div
                                              id="Delete_Accommodation"
                                              class="Btn my-2"
                                              onClick={() =>
                                                handleAccommodationDeleteRow(
                                                  accommodation.id
                                                )
                                              }
                                            >
                                              <div class="sign">
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 30 30"
                                                  width="30px"
                                                  height="30px"
                                                >
                                                  <path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z" />
                                                </svg>
                                              </div>
                                            </div>
                                          )}
                                        </td>
                                        {/* )} */}
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>

                              <div className="mt-4">
                                <div className="d-flex justify-content-between  ">
                                  <h6 className="text-primary">Occupancy</h6>
                                </div>
                              </div>
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th>Emp </th>

                                    <th>
                                      Emp ID <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Name <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Gender <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Phone <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      Email <span className="astik"> *</span>
                                    </th>
                                    <th>
                                      DOB <span className="astik"> *</span>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {roomsData?.map((room, index) => (
                                    <tr key={room.id}>
                                      <td>
                                        <div class="form-check form-check-info">
                                          <label class="form-check-label">
                                            <input
                                              type="checkbox"
                                              class="form-check-input"
                                              checked={
                                                room?.data?.is_employee ===
                                                "Yes"
                                                  ? true
                                                  : false
                                              }
                                              disabled={
                                                basicDetails?.booking_for ===
                                                "self"
                                                  ? true
                                                  : false
                                              }
                                              onChange={(e) =>
                                                handleRoomChange(room.id, {
                                                  name: "",
                                                  emp_id: "",
                                                  email: "",
                                                  phone: "",
                                                  dob: new Date(),
                                                  gender: "",
                                                  is_employee: e.target.checked
                                                    ? "Yes"
                                                    : "No",
                                                })
                                              }
                                            />
                                            <i class="input-helper"></i>
                                          </label>
                                        </div>
                                      </td>

                                      <td style={{ width: "15%" }}>
                                        <Select
                                          className={classNames(
                                            "form-select-select",
                                            {
                                              "is-invalid":
                                                validateForOccupancyDataRow[
                                                  index
                                                ]?.data?.emp_id ||
                                                (room?.data?.is_employee ===
                                                  "Yes" &&
                                                  !validateForOccupancyDataRow[
                                                    index
                                                  ]?.data?.emp_id &&
                                                  validateForOccupancyDataRow[
                                                    index
                                                  ]?.data?.empIdPattern),
                                            }
                                          )}
                                          name="emp_id"
                                          options={getEmployeeDataByStatusCode.map(
                                            (val) => {
                                              return {
                                                ...val,
                                                isDisabled: roomsData
                                                  ?.map((item) => {
                                                    return item?.data?.emp_id
                                                      ?.value;
                                                  })
                                                  .includes(val?.value),
                                              };
                                            }
                                          )}
                                          defaultValue={
                                            getEmployeeDefaultByStatusCode[0]
                                          }
                                          value={
                                            room?.data?.is_employee === "Yes"
                                              ? room?.data?.emp_id
                                              : ""
                                          }
                                          onChange={(selectedOption) =>
                                            handleRoomChange(room.id, {
                                              ...room.data,
                                              emp_id: selectedOption,
                                            })
                                          }
                                          isDisabled={
                                            room?.data?.is_employee === "Yes" &&
                                            basicDetails?.booking_for !== "self"
                                              ? false
                                              : true
                                          }
                                        />

                                        <small className="isValidate">
                                          {validateForOccupancyDataRow[index]
                                            ?.data?.emp_id &&
                                            "This field is required"}
                                          {room?.data?.is_employee === "Yes" &&
                                            !validateForOccupancyDataRow[index]
                                              ?.data?.emp_id &&
                                            validateForOccupancyDataRow[index]
                                              ?.data?.empIdPattern &&
                                            "Please select others"}
                                          {room?.data?.is_employee === "Yes" &&
                                            !validateForOccupancyDataRow[index]
                                              ?.data?.emp_id &&
                                            validateForOccupancyDataRow[index]
                                              ?.data
                                              ?.validationForCheckingDuplicateValue &&
                                            "Same Employee Id not allowed"}
                                        </small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          disabled={
                                            room?.data?.is_employee === "Yes"
                                              ? true
                                              : false
                                          }
                                          placeholder="Enter Full Name"
                                          value={room?.data?.name}
                                          name="name"
                                          className={classNames(
                                            "form-control form-control-sm",
                                            {
                                              "is-invalid":
                                                validateForOccupancyDataRow[
                                                  index
                                                ]?.data?.name,
                                            }
                                          )}
                                          onChange={(e) =>
                                            handleRoomChange(room.id, {
                                              ...room.data,
                                              name: e.target.value,
                                            })
                                          }
                                        />
                                        <small className="isValidate">
                                          {validateForOccupancyDataRow[index]
                                            ?.data?.name &&
                                            "This field is required"}
                                          {room?.data?.is_employee === "No" &&
                                            !validateForOccupancyDataRow[index]
                                              ?.data?.name &&
                                            validateForOccupancyDataRow[index]
                                              ?.data?.namePattern &&
                                            "Fullname is not valid"}
                                        </small>
                                      </td>
                                      <td style={{ width: "15%" }}>
                                        <select
                                          className={classNames(
                                            "form-select form-control-sm",
                                            {
                                              "is-invalid":
                                                validateForOccupancyDataRow[
                                                  index
                                                ]?.data?.gender,
                                            }
                                          )}
                                          disabled={
                                            room?.data?.is_employee === "Yes"
                                              ? true
                                              : false
                                          }
                                          placeholder="Enter Employee ID"
                                          onChange={(e) =>
                                            handleRoomChange(room.id, {
                                              ...room.data,
                                              gender: e.target.value,
                                            })
                                          }
                                          value={room?.data?.gender}
                                        >
                                          <option value="" selected disabled>
                                            Select...
                                          </option>
                                          <option value="Male">Male</option>
                                          <option value="Female">Female</option>
                                          <option value="Others">Others</option>
                                        </select>
                                        {/* )} */}
                                        <small className="isValidate">
                                          {validateForOccupancyDataRow[index]
                                            ?.data?.gender &&
                                            "This field is required"}
                                        </small>
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          disabled={
                                            room?.data?.is_employee === "Yes"
                                              ? true
                                              : false
                                          }
                                          value={room?.data?.phone}
                                          name="phone"
                                          className={classNames(
                                            "form-control form-control-sm",
                                            {
                                              "is-invalid":
                                                validateForOccupancyDataRow[
                                                  index
                                                ]?.data?.phone,
                                            }
                                          )}
                                          placeholder="Enter Phone"
                                          onChange={(e) => {
                                            let { value } = e.target;

                                            // Limit the number of digits to 6
                                            if (value.length > 10) {
                                              value = value.slice(0, 10);
                                            }
                                            handleRoomChange(room.id, {
                                              ...room.data,
                                              phone: value,
                                            });
                                          }}
                                        />
                                        <small className="isValidate">
                                          {validateForOccupancyDataRow[index]
                                            ?.data?.phone &&
                                            "This field is required"}
                                          {room?.data?.is_employee === "No" &&
                                            !validateForOccupancyDataRow[index]
                                              ?.data?.phone &&
                                            validateForOccupancyDataRow[index]
                                              ?.data?.phonePattern &&
                                            "Phone no is not valid"}
                                        </small>
                                      </td>
                                      <td>
                                        <input
                                          type="email"
                                          disabled={
                                            room?.data?.is_employee === "Yes"
                                              ? true
                                              : false
                                          }
                                          value={room?.data?.email}
                                          name="email"
                                          placeholder="Enter Email ID"
                                          className={classNames(
                                            "form-control form-control-sm",
                                            {
                                              "is-invalid":
                                                validateForOccupancyDataRow[
                                                  index
                                                ]?.data?.email,
                                            }
                                          )}
                                          onChange={(e) =>
                                            handleRoomChange(room.id, {
                                              ...room?.data,
                                              email: e.target.value,
                                            })
                                          }
                                        />
                                        <small className="isValidate">
                                          {validateForOccupancyDataRow[index]
                                            ?.data?.email &&
                                            "This field is required"}
                                          {room?.data?.is_employee === "No" &&
                                            !validateForOccupancyDataRow[index]
                                              ?.data?.email &&
                                            validateForOccupancyDataRow[index]
                                              ?.data?.emailPattern &&
                                            "Email Id is not valid"}
                                        </small>
                                      </td>
                                      <td>
                                        <DatePicker
                                          dateFormat="dd/MM/yyyy"
                                          maxDate={new Date()}
                                          peekNextMonth
                                          showMonthDropdown
                                          showYearDropdown
                                          dropdownMode="select"
                                          showWeekNumbers
                                          selected={new Date(room?.data?.dob)}
                                          onChange={(e) =>
                                            handleRoomChange(room.id, {
                                              ...room.data,
                                              dob: e,
                                            })
                                          }
                                          disabled={
                                            room?.data?.is_employee === "Yes"
                                              ? true
                                              : false
                                          }
                                          customInput={
                                            <ExampleCustomInput
                                              disabled={
                                                room?.data?.is_employee ===
                                                "Yes"
                                                  ? true
                                                  : false
                                              }
                                              validate={
                                                validateForOccupancyDataRow[
                                                  index
                                                ]?.data?.dob
                                              }
                                            />
                                          }
                                        />

                                        <br />
                                        <small className="isValidate">
                                          {validateForOccupancyDataRow[index]
                                            ?.data?.dob &&
                                            "This field is required"}
                                        </small>
                                      </td>
                                      {basicDetails?.booking_for !== "self" && (
                                        <>
                                          <td>
                                            <div
                                              id="add_travel"
                                              class="Btn my-2"
                                              onClick={handleAddRoom}
                                            >
                                              <div
                                                class="sign"
                                                title="Add Travel"
                                              >
                                                <svg
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 24 24"
                                                  width="24px"
                                                  height="24px"
                                                  fill-rule="evenodd"
                                                >
                                                  <path
                                                    fill-rule="evenodd"
                                                    d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                                                  />
                                                </svg>
                                              </div>
                                            </div>
                                          </td>

                                          <div
                                            id="Delete_Travel"
                                            class="Btn my-2"
                                            onClick={() =>
                                              handleRoomsDeleteRow(room.id)
                                            }
                                          >
                                            <div class="sign">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                viewBox="0 0 30 30"
                                                width="30px"
                                                height="30px"
                                              >
                                                <path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z" />
                                              </svg>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          )}
                        </div>

                        {/* ===================Special Request================ */}
                        <div
                          style={{
                            border: "1px solid lightgrey",
                            marginTop: "1rem",
                            padding: "1rem",
                            borderRadius: "20px",
                          }}
                        >
                          <div className="row mt-2">
                            <div className="col-12 col-lg-12">
                              <div className="form-group">
                                <label>Special Request:</label>
                                <textarea
                                  name="special_request"
                                  className="form-control form-control-sm"
                                  placeholder="Seat preference, Food Preference...... "
                                  onChange={inputEvent}
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row my-4 text-center">
                          <div className="col-12">
                            <button
                              id="res"
                              type="button"
                              className="btn text-white mx-2 btn-sm"
                              style={{
                                background: "rgb(209, 65, 36)",
                              }}
                              onClick={() => handleCancel()}
                            >
                              Cancel
                            </button>
                            <button
                              id="res"
                              type="button"
                              className="btn text-white mx-2 btn-sm"
                              style={{
                                background: "rgb(209, 65, 36)",
                              }}
                              onClick={() => handleReset()}
                            >
                              Reset
                            </button>
                            <button
                              id="res"
                              type="button"
                              className="btn btn-primary btn-sm"
                              onClick={() => validateError(true)}
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TravelRequestForm;
