import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";
import { NavLink, useNavigate } from "react-router-dom";
import axios from "axios";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import logo from "./AceAppsTitle_new.svg";
const Navbar = () => {
  const navigate = useNavigate();
  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));
  const [cookies, setCookie, removeCookie] = useCookies();
  const [getData, setGetData] = useState({});
  const [isManager, setIsManager] = useState(false);

  useEffect(() => {
    document.getElementById("body").classList.add("sidebar-icon-only");
  }, []);
  return (
    <>
      <nav className="navbar default-layout-navbar p-0 fixed-top d-flex flex-row">
        <div className="navbar-menu-wrapper w-100 d-flex align-items-stretch">
          <div className="w-100 d-flex">
            <div className="w-100 d-flex align-items-center justify-content-between">
              <div>
                <button
                  className="navbar-toggler ms-4 navbar-toggler align-self-center"
                  type="button"
                  data-bs-toggle="minimize"
                  onClick={() =>
                    document
                      .getElementById("body")
                      .classList.toggle("sidebar-icon-only")
                  }
                >
                  <span className="mdi mdi-menu"></span>
                </button>
              </div>
              <div className="text-center">
                <NavLink to="/" style={{ textDecoration: "none" }}>
                  <img src={logo} alt="logo" className="w-50 h-50" />
                </NavLink>
              </div>
              <div>
                <ul className="navbar-nav navbar-nav-right">
                  <li className="nav-item nav-profile dropdown">
                    <a
                      // className="nav-link dropdown-toggle"
                      className="nav-link"
                      id="profileDropdown"
                      href="#"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <div className="nav-profile-img">
                        <img src={LocalStorageData?.photo} alt="image" />
                        <span className="availability-status online"></span>
                      </div>
                      <div className="nav-profile-text">
                        <p className="mb-1 text-black">
                          {LocalStorageData?.name}
                        </p>
                      </div>
                    </a>

                    <ReactTooltip
                      anchorId="fullscreen_tooltip"
                      place="bottom"
                      content="Go Full Screen"
                    />
                  </li>

                  <ReactTooltip
                    anchorId="logout_tooltip"
                    place="bottom"
                    content="Logout"
                  />

                  <li
                    id="logout_tooltip"
                    className="nav-item nav-logout d-none d-lg-block"
                  >
                    <NavLink
                      className="nav-link"
                      onClick={() => {
                        return (
                          localStorage.clear(), removeCookie(), navigate("/")
                          // alert("");
                        );
                      }}
                      to="/"
                    >
                      <i
                        className="mdi mdi-logout"
                        style={{ color: "#d14124" }}
                      ></i>
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <button
            className="navbar-toggler navbar-toggler-right d-lg-none align-self-center"
            type="button"
            onClick={() =>
              document.getElementById("sidebar").classList.toggle("active")
            }
          >
            <span className="mdi mdi-menu"></span>
          </button>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
