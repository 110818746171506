import React, { useEffect, useState } from "react";
import axios from "axios";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import Footer from "../../Partials/Footer";
import PureModal from "react-pure-modal";
import { useAlert } from "react-alert";
import Navbar from "../../Partials/Navbar";
import Page_Header from "../../Partials/Page_Header";
import Sidebar from "../../Partials/Sidebar";
import { NavLink, useNavigate } from "react-router-dom";
import { putRequest } from "../../Utils/api";
const GetExpenseRequestForm_Data = () => {
  const alert2 = useAlert();
  const navigate = useNavigate();
  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));
  const [getExpenseData, setExpenseData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [viewRequestModal, setViewRequestModal] = useState(false);
  const [viewRequestData, setViewRequestData] = useState([]);
  const [gettravelrequestdata, setGettravelrequestdata] = useState([]);
  const [renderComponent, setRenderComponent] = useState(false);
  const [id, setId] = useState("");
  const [counterList, setCounterList] = useState([]);

  const [originalData, setOriginalData] = useState([]);

  const months = [
    "All records",
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // const monthsWithValues = originalData.filter(
  //   (val) => val?.BillMonth.split(" ")[0] !== ""
  // );

  // const filteredMonths = months.filter((month) =>
  //   monthsWithValues.includes(month)
  // );

  const currentDate = new Date();
  const currentMonth = new Intl.DateTimeFormat("en-US", {
    month: "long",
  }).format(currentDate);

  function convertDateFormat(inputDate) {
    const date = new Date(inputDate); // Parse the input date string

    if (isNaN(date)) {
      // Handle invalid date
      return "Invalid Date";
    }

    // Extract date components (day, month, year)
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());

    // Create the output date string in "dd-mm-yy" format
    const outputDate = `${day}-${month}-${year}`;

    return outputDate;
  }
  const handleMonthChange = (e) => {
    // console.log(e.target.value);
    const selectedMonth = e.target.value;

    if (selectedMonth === "All records") {
      // Display all records (no filtering)
      //console.log("oooooooo", originalData);
      setExpenseData(originalData);
    } else {
      // Filter records for the selected month
      const filter = originalData.filter(
        (x) => x?.BillMonth?.split(" ")[0] === selectedMonth
      );
      //console.log("file", filter);
      setExpenseData(filter);
    }
    // const filter = originalData.filter(
    //   (x) => x?.BillMonth.split(" ")[0] === e.target.value
    // );
  };

  // useEffect(() => {
  //   const currentDate = new Date();
  //   const currentMonth = new Intl.DateTimeFormat("en-US", {
  //     month: "long",
  //   }).format(currentDate);
  //   console.log("currr", currentMonth);
  //   const currentYear = currentDate.getFullYear();
  //   async function getData() {
  //     setLoading(true);
  //     await axios
  //       .get(`${process.env.REACT_APP_BASE_URL}/all_expense_request`, {
  //         headers: { authorization: LocalStorageData?.generate_auth_token },
  //       })
  //       .then((result) => {
  //         const resp = result.data;
  //         console.log("resp", resp);
  //         const activeExpenses = resp?.filter((val) => val?.status === 1);

  //         // const activeExpenses = resp?.filter(
  //         //   (val) =>
  //         //     val?.status === 1 &&
  //         //     val?.Finance_approval === "Approved" &&
  //         //     val?.BillMonth.split(" ")[0] === currentMonth
  //         // );
  //         setOriginalData(resp);
  //         // setGettravelrequestdata(
  //         //   LocalStorageData?.zoho_role === "Accounts"
  //         //     ? resp.filter(
  //         //         (x) =>
  //         //           x?.Finance_approval === "Approved" &&
  //         //           x?.BillMonth.split(" ")[0] === currentMonth
  //         //       )
  //         //     : resp
  //         // );

  //         return setExpenseData(activeExpenses), setLoading(false);
  //       })
  //       .catch((err) => {
  //         if (err.response.status === 500) {
  //           navigate("/error_500");
  //         } else {
  //           navigate("/error_403");
  //         }
  //       });
  //   }
  //   getData();
  // }, [renderComponent]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = new Intl.DateTimeFormat("en-US", {
      month: "long",
    }).format(currentDate);
    //console.log("currr", currentMonth);
    async function getData() {
      setLoading(true);
      await axios
        .get(
          `${process.env.REACT_APP_BASE_URL}/get_expense_request_by_email_id/${LocalStorageData?.email}`,
          {
            headers: { authorization: LocalStorageData?.generate_auth_token },
          }
        )
        .then((result) => {
          const resp = result.data;
          const activeExpenses = resp?.filter((val) => val?.status === 1);
          // console.log("resp", resp);
          setOriginalData(resp);

          return setExpenseData(activeExpenses), setLoading(false);
        })
        .catch((err) => {
          if (err.response.status === 500) {
            navigate("/error_500");
          } else {
            navigate("/error_403");
          }
        });
    }
    getData();
  }, [renderComponent]);

  // useEffect(() => {
  //   async function get_counterList() {
  //     await axios
  //       .get(
  //         `${process.env.REACT_APP_BASE_URL}/documents_counter/${LocalStorageData?.user_id}/${LocalStorageData?.emp_id}/${LocalStorageData?.zoho_role}/${LocalStorageData?.email}`,
  //         {
  //           headers: { authorization: LocalStorageData?.generate_auth_token },
  //         }
  //       )
  //       .then((res) => {
  //         return setCounterList(res?.data);
  //       })
  //       .catch((err) => {
  //         if (err.response.status === 500) {
  //           navigate("/error_500");
  //         } else {
  //           navigate("/error_403");
  //         }
  //       });
  //   }

  //   get_counterList();
  // }, [renderComponent]);
  // =========Revoke RRequest=========

  // const handleRevoke = (id) => {
  //   const res = putRequest(`/revoke_expense_request/${id}`);
  //   if (res?.status) {
  //     setRenderComponent(!renderComponent);
  //   }
  // };
  const handleRevoke = async (id) => {
    let res = window.confirm("Do You Want To Revoke This Request");

    if (res === true) {
      const endpoint = `/revoke_expense_request/${id}`;

      const res2 = await putRequest(endpoint);
      // console.log("dadadadadada", res2);

      if (res2.data?.message === "Deleted successfully") {
        setRenderComponent(!renderComponent);

        alert?.success("Deleted Successfully");
      } else {
        alert?.error("something went wrong");
      }
    }
  };
  function convertDateFormat(inputDate) {
    const date = new Date(inputDate); // Parse the input date string

    if (isNaN(date)) {
      // Handle invalid date
      return "Invalid Date";
    }

    // Extract date components (day, month, year)
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = String(date.getFullYear());

    // Create the output date string in "dd-mm-yy" format
    const outputDate = `${day}-${month}-${year}`;

    return outputDate;
  }
  // const handleRevokeRequest =
  return (
    <div className="container-scroller">
      <Navbar />
      <div className="container-fluid page-body-wrapper ">
        <Sidebar requestCounter={counterList?.Travel_Request_By_User_ID} />
        <div className="main-panel ">
          <div className="content-wrapper" style={{ borderRadius: "20px" }}>
            {/* <Page_Header
              page_heading="Travel Requests List"
              page_title="Create new request"
              page_title_icon="mdi-wallet-travel"
              page_title_button=""
              page_title_button_link="/travelrequestform"
            /> */}
            <div className="d-flex justify-content-end mb-3">
              {/* <NavLink to="/travelrequestform">
                <button className="btn btn-sm btn-success mx-3">
                  Raise Request
                </button>
              </NavLink> */}
              {/* {isManager && ( */}
              {/* <NavLink to="/travelrequestreceived">
                <button className="btn btn-sm btn-dark">Take Action</button>
              </NavLink> */}
              {/* )} */}
            </div>
            {loading && (
              <div class="loader-container">
                <div class="loader">
                  <img
                    class="logo"
                    src="/assets/images/aceAppsLogo.png"
                    alt="Logo"
                  />
                </div>
              </div>
            )}
            <div className="row">
              <div
                className="col-lg-12 grid-margin stretch-card"
                // style={{ width: "fit-content" }}
              >
                <div className="card" style={{ borderRadius: "20px" }}>
                  <div className="card-body overflow-auto">
                    <div className="d-flex justify-content-between align-items-center">
                      <span class="card-description">My Expenses</span>
                    </div>
                    <div className="d-flex justify-content-lg-end my-2 justify-content-center w-25 ">
                      <select
                        className="form-select form-select-sm "
                        onChange={handleMonthChange}
                      >
                        {months.map((month) => (
                          <option key={month} value={month}>
                            {month}
                          </option>
                        ))}
                        {/* <option value="" selected disabled>
                          CHOOSE THE MONTH
                        </option>
                        {months?.map((val) => {
                          return <option val={val}>{val}</option>;
                        })} */}
                        Pending
                      </select>
                    </div>
                    <table className="table table-striped overflow-auto">
                      <thead>
                        <tr>
                          <th>Sr.no</th>
                          <th>Requested On</th>
                          <th>Requested By</th>
                          {/* <th>Employee ID</th> */}
                          <th>Client Code</th>
                          <th>Project Code</th>
                          <th>Total Bill Amount</th>
                          <th>Status</th>
                          <th></th>
                        </tr>
                      </thead>
                      {getExpenseData?.length > 0 ? (
                        <tbody>
                          {getExpenseData
                            ?.slice()
                            .reverse()
                            ?.map((val, index) => {
                              return (
                                <tr
                                  className={
                                    val?.draft === "true" ? "bg-warning" : ""
                                  }
                                >
                                  <td>{index + 1}</td>
                                  <td>{convertDateFormat(val?.createdAt)}</td>
                                  <td>
                                    {val?.created_by_name}
                                    {" - "}
                                    {LocalStorageData?.emp_id}
                                  </td>

                                  <td>
                                    {val?.clients.map((item, index) => {
                                      return <span>{item?.client_name}</span>;
                                    })}
                                  </td>
                                  <td>
                                    {val?.clients.map((item, index) => {
                                      return <span>{item?.project_name}</span>;
                                    })}
                                  </td>
                                  {/* <td>
                                    {val?.clients.map((item, index) => {
                                      console.log("item", item?.billable);
                                      return (
                                        <span>
                                          {item?.billable === "true"
                                            ? "Yes"
                                            : "No"}
                                        </span>
                                      );
                                    })}
                                  </td>
                                  <td>
                                    {val?.clients.map((item, index) => {
                                      return (
                                        <span>
                                          {item?.expenses.map((exp, index) => {
                                            return (
                                              <span>
                                                {exp?.Category} {}
                                              </span>
                                            );
                                          })}
                                        </span>
                                      );
                                    })}
                                  </td> */}
                                  <td>
                                    {val?.clients.map((item, index) => {
                                      return (
                                        <span>
                                          {
                                            (item?.SettlementCurrency).split(
                                              "_"
                                            )[1]
                                          }
                                        </span>
                                      );
                                    })}{" "}
                                    {val?.clients.map((item, index) => {
                                      return (
                                        <span>
                                          {Math.round(
                                            item?.expenses.reduce(
                                              (accumulator, val) => {
                                                const billAmount = parseFloat(
                                                  val.SettlementAmount
                                                ); // Parse the value as a float
                                                return (
                                                  accumulator +
                                                  (isNaN(billAmount)
                                                    ? 0
                                                    : billAmount)
                                                ); // Add to accumulator if it's a valid number
                                              },
                                              0
                                            )
                                          )}
                                        </span>
                                      );
                                    })}
                                  </td>
                                  <td>{val?.Request_Status}</td>
                                  <td>
                                    {val?.draft === "true" ? (
                                      <button
                                        className="btn btn-primary btn-sm"
                                        type="button"
                                        onClick={() => {
                                          return (
                                            // setId(val._id),
                                            navigate(
                                              `/draftedexpeseedit/${val?._id}`
                                            )
                                          );
                                        }}
                                      >
                                        Edit{" "}
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-primary btn-sm"
                                        type="button"
                                        onClick={() => {
                                          return (
                                            // setId(val._id),
                                            navigate(
                                              `/viewexpesedetails/${val?._id}`
                                            )
                                          );
                                        }}
                                      >
                                        View{" "}
                                      </button>
                                    )}
                                    {val?.Managers_approval === "Pending" && (
                                      <button
                                        className="btn text-white  bg-danger btn-sm"
                                        type="button"
                                        onClick={() => {
                                          handleRevoke(val?._id);
                                        }}
                                      >
                                        Revoke{" "}
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                        </tbody>
                      ) : (
                        <div className=" w-100">
                          <small className="my-3 fw-bold fs-4 text-secondary">
                            No Request as of now
                          </small>
                        </div>
                      )}
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* ========View Request Modal=========== */}
          <footer className="footer">
            <Footer />
          </footer>
        </div>
      </div>
    </div>
  );
};

export default GetExpenseRequestForm_Data;
