import React, { useState, forwardRef } from "react";
import Navbar from "../../Partials/Navbar";
import Sidebar from "../../Partials/Sidebar";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./Expense.css";
import "react-tooltip/dist/react-tooltip.css";
import { FaHistory } from "react-icons/fa";
import getRequest, {
  postRequest,
  putRequest,
  deleteRequest,
} from "../../Utils/api";
import Footer from "../../Partials/Footer";
import "react-datepicker/dist/react-datepicker.css";
import { useEffect } from "react";
import DatePicker from "react-datepicker";
import { AiOutlineDownload } from 'react-icons/ai';
import {
  AiOutlineCloudUpload,
  AiOutlineDelete,
  AiOutlineEye,
} from "react-icons/ai";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import { useForm } from "react-hook-form";
import { useNavigate, NavLink } from "react-router-dom";
import { useAlert } from "react-alert";
import { useParams } from "react-router-dom";
import axios from "axios";
moment().tz("Asia/Kolkata").format();

const category = [
  "Domain & Hosting Charges",
  "Hotel Expenses",
  "Legal Charges",
  "Meal & Refreshment",
  "Postage & Courier Charges",
  "Preliminary Charges",
  "Printing & Stationery Exp",
  "Telephone Expense",
  "Tour & Travelling Expense",
  "Website Charges",
  "Software",
  "Hardware Asset",
  "Others",
];
const validationSchema = Yup.object({
  clients: Yup.array().of(
    Yup.object().shape({
      client_name: Yup.string().required("required"),
      project_name: Yup.string().required("required"),
      // billable: Yup.boolean().required("required"),
      expenses: Yup.array().of(
        Yup.object().shape({
          // InvoiceNo: Yup.string().required("required"),
          ExpenseDate: Yup.date().required("required"),
          ExpenseDetails: Yup.string().required("required"),
          Category: Yup.string().required("required"),
          ExpenseAmount: Yup.number()
            .typeError("Bill Amount must be a number")
            .required("required"),
          Expense_Currency: Yup.string().required("required"),
          //SettlementCurrency: Yup.string().required("required"),
          // ExchangeRate: Yup.number()
          //   .typeError("Bill Amount must be a number")
          //   .required("required"),
          // Remarks: Yup.string().required("required"),
          // documents: Yup.array().min(1, "At least one file is required"),
          // .test("totalSize", "Total file size exceeds 5MB", (value) => {
          //   if (!value) return true; // Allow empty field without errors
          //   const totalSize = value.reduce(
          //     (acc, file) => acc + (file ? file.size : 0),
          //     0
          //   );
          //   return totalSize <= 5 * 1024 * 1024; // 5MB limit
          // })
          // .test("fileType", "Invalid file type", (value) => {
          //   if (!value) return true; // Allow empty field without errors
          //   return value.every((file) => {
          //     const allowedTypes = [
          //       "image/jpeg",
          //       "image/jpg",
          //       "image/png",
          //       "application/pdf",
          //     ];
          //     return file && allowedTypes.includes(file.type);
          //   });
          // }),
        })
      ),
    })
  ),
});

// =====================Data End=============
const Edit_Drafted_User_Expense = () => {
  const alert = useAlert();
  const { _id } = useParams();
  const [loading, setLoading] = useState(false);
  //const [rateLoading, setRateLoading] = useState(false);
  const [shouldResetForm, setShouldResetForm] = useState(false);
  const LocalStorageData = JSON.parse(localStorage.getItem("loggedin"));
  const [projectDetails, setProjectDetails] = useState([]);
  const [filteredProjectIds, setFilteredProjectIds] = useState([]);
  const [renderComponent, setRenderComponent] = useState(false);
  const [projects, setProjects] = useState([]);
  const [currency, setCurrency] = useState([]);
  const [getExpenseData, setgetExpenseData] = useState([]);
  const [clientId, setClientId] = useState();
  const navigate = useNavigate();

  const [clients, setClients] = useState([]);
  // ==================Fetch Data by Id============
  useEffect(() => {
    const getData = async () => {
      const response = await getRequest(`/get_expense_request_by_id/${_id}`);
      if (response.success) {
        setClients(response?.data);
        // console.log("sssss", response.data);
        formik.setValues(response?.data[0]);
      }
    };
    getData();
  }, []);

  // ==================End Fetch Data by Id============
  //Formik
  const initialFormValues = {
    Id: clients.Id,
    client_name: clients.client_name,
    project_name: clients.project_name,
    SettlementCurrency: clients.SettlementCurrency,
    expenses: clients?.expenses?.map((expense) => ({
      InvoiceNo: expense.InvoiceNo,
      Billable: expense.Billable,
      ExpenseDate: expense.ExpenseDate || "", // Initialize as an empty string
      ExpenseDetails: expense.ExpenseDetails,
      Category: expense.Category,
      ExpenseAmount: expense.ExpenseAmount,
      Expense_Currency: expense.Expense_Currency,
      SettlementAmount: expense.SettlementAmount,
      ExchangeRate: expense.ExchangeRate,
      Remarks: expense.Remarks,
      // documents: expense.documents,
    })),
  };

  const formik = useFormik({
    initialValues: initialFormValues,
    validationSchema,
    onSubmit: (values) => {
      //console.log(values);
      // Handle form submission here
      handleFormSubmit();
    },
  });

  // ===================Fetching all the clients and prject Codes=================
  const fetchProjectDetails = async () => {
    try {
      const endpoint = "/client_project";
      const response = await getRequest(
        endpoint,
        LocalStorageData?.generate_auth_token
      );

      if (response.success) {
        setProjectDetails(response.data);
      } else {
        // Handle the error here, e.g., display an error message
        console.error(`Failed to fetch project details: ${response.message}`);
      }
    } catch (error) {
      // Handle unexpected errors here
      console.error("An error occurred while fetching project details:", error);
    }
  };
  const fetchClientsData = async () => {
    try {
      const endpoint = "/clients"; // Define the API endpoint
      const response = await getRequest(
        endpoint,
        LocalStorageData?.generate_auth_token
      );

      if (response.success) {
        setProjects(response.data);
      } else {
        // Handle the error here, e.g., display an error message
        console.error(`Failed to fetch clients data: ${response.message}`);
      }
    } catch (error) {
      // Handle unexpected errors here
      console.error("An error occurred while fetching clients data:", error);
    }
  };

  const fetchCurrencies = async () => {
    try {
      const endpoint = "/currencies"; // Define the API endpoint
      const response = await getRequest(
        endpoint,
        LocalStorageData?.generate_auth_token
      );

      if (response.success) {
        setCurrency(response.data);
      } else {
        // Handle the error here, e.g., display an error message
        console.error(`Failed to fetch clients data: ${response.message}`);
      }
    } catch (error) {
      // Handle unexpected errors here
      console.error("An error occurred while fetching clients data:", error);
    }
  };

  const handleClientChange = (event, index) => {
    const clientName = event.target.value;
    // Create a copy of the form values
    const updatedValues = { ...formik.values };

    // Update the client_name for the specified client
    updatedValues.clients[index].client_name = clientName;

    // Find the selected client within the updated values
    const selectedClient = projectDetails?.filter(
      (client) => client.client_name === clientName
    );
    // If a selected client is found, update the project_name
    filteredProjectIds[index] = selectedClient;
    updatedValues.clients[index].project_name = selectedClient.project_name;

    // Update Formik values with the modified object
    formik.setValues(updatedValues);
    setRenderComponent(!renderComponent);
  };
  useEffect(() => {
    fetchProjectDetails();
    fetchCurrencies();
    fetchClientsData();
  }, [renderComponent]);
  // ===================End Fetching all the clients and prject Codes=================

  // Define the addExpenseRow function outside of the rendering code
  const addExpenseRow = (clientIndex) => {
    const newExpense = {
      InvoiceNo: "",
      Billable: "",
      ExpenseDate: new Date(),
      ExpenseDetails: "",
      Category: "",
      ExpenseAmount: "",
      Expense_Currency: "₹_INR",
      SettlementAmount: "",
      ExchangeRate: "",
      Remarks: "",
      documents: [],
    };
    const updatedClients = [...formik?.values?.clients];
    formik.setFieldValue(
      `clients[${clientIndex}].expenses[${formik?.values?.clients[clientIndex].expenses.length}]`,
      newExpense
    );
    updatedClients[clientIndex].expenses.push(newExpense);
    setClients(updatedClients);
  };

  const deleteExpenseRow = (e, clientIndex, expenseIndex) => {
    e.preventDefault();
    // Create a copy of the clients array
    const updatedClients = [...formik?.values?.clients];

    // Remove the specified expense row
    updatedClients[clientIndex].expenses.splice(expenseIndex, 1);

    // Update the state with the modified clients array
    setClients(updatedClients);
  };




  //Add Clients For Expenses

  const handleRevoke = async (event, id) => {
    event.preventDefault();
    let res = window.confirm("Do You Want To Revoke This Request");

    if (res === true) {
      const endpoint = `/revoke_expense_request/${id}`;

      const res2 = await putRequest(endpoint);

      if (res2.data?.message === "Deleted successfully") {
        navigate("/view_expense");
        alert?.success("Revoked Successfully");
      } else {
        alert?.error("something went wrong");
      }
    }
  };
  function isEmptyObject(obj) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        return "No"; // The object has at least one property
      }
    }
    return "Yes"; // The object is empty
  }
  const handleFormSave = async () => {
    formik?.validateForm().then(async (val) => {
      const isValid = isEmptyObject(val);
      if (isValid === "No") {
        alert.error("Mandatory Fields are Required");
      } else {
        const formData = formik?.values?.clients;
        const response = await putRequest(`/update_expense_request/${_id}`, {
          Managers_approval: "Pending",
          Request_Status: "drafted",
          draft: "true",
          // status: 1,
          // created_by: LocalStorageData?.email,
          clients: formData,
        });

        if (
          response.success &&
          response.data.message === "Updated Expense Successfully"
        ) {
          alert?.success("Saved and Updated Successfully");
          navigate("/view_expense");
        }
      }
    });
  };
  const handleFormSubmit = async () => {
    const formData = formik?.values?.clients;
    const response = await putRequest(`/update_expense_request/${_id}`, {
      //Managers_approval: "Pending",
      Managers_approval:
        LocalStorageData?.zoho_role === "Management" ? "Approved" : "Pending",
      Finance_approval: "Pending",
      draft: "false",
      //Request_Status: "Pending",
      Request_Status:
        LocalStorageData?.zoho_role === "Management"
          ? "Manager's Approved"
          : "Pending",
      status: 1,
      created_by: LocalStorageData?.email,
      created_by_name: LocalStorageData?.owner_name,
      clients: formData,
    });
    if (
      response.success &&
      response.data.message === "Updated Expense Successfully"
    ) {
      // const clients = await Object.assign({}, response.data?.data?.clients[0]);
      try {
        const emailTemplateData = {
          user_name: LocalStorageData?.owner_name,
          created_by: LocalStorageData?.email,
          clients: formik?.values?.clients,
          email_subject: "Expense Submitted Successfully",
          template_name: "Expense_Request_Template",
        };
        await postRequest("/Expense_email", emailTemplateData);
        alert?.success("Request Sent");
        navigate("/view_expense");
      } catch (error) {
        alert?.error("Email was not sent. Try again");
      }
    }
  };
  // 2. Update your handleFileChange function
  const uploadFiles = async (files) => {
    const formData = new FormData();
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }
    const response = await postRequest("/upload", formData, {
      headers: {
        "Content-Type": "multipart/form-data", // Important for handling files
      },
    });

    return response;
  };

  const handleFileChange = async (event, clientIndex, expenseIndex) => {
    const files = event.target.files; // Get the selected files
    const uploadedFileResponse = await uploadFiles(files);
    const updatedClients = [...formik?.values?.clients]; // Create a copy of the clients array

    if (uploadedFileResponse.success) {
      // Store the filenames and file paths in the documents array
      const existingDocuments = [
        ...updatedClients[clientIndex].expenses[expenseIndex].documents,
      ];
      const mergedDocuments = existingDocuments.concat(
        uploadedFileResponse.data.filenames.map((filename, index) => ({
          filenames: filename,
          filepath: uploadedFileResponse.data.filepath[index], // Modify this to get the actual file path
          originalname: uploadedFileResponse.data.originalname[index],
        }))
      );

      updatedClients[clientIndex].expenses[expenseIndex].documents =
        mergedDocuments;
    } else {
      // Handle the case where no files are selected or an error occurs
      alert?.error("Please check the file type and try again");
    }

    // Update Formik values
    formik.setFieldValue(
      `clients[${clientIndex}].expenses[${expenseIndex}].documents`,
      updatedClients[clientIndex].expenses[expenseIndex].documents
    );
  };

  const handleDeleteFile = async (fileName, clientIndex, expenseIndex) => {
    const endpoint = `/deletefile/${fileName}`;
    const res2 = await deleteRequest(endpoint);
    if (res2?.data?.status === "Deleted") {
      alert?.success(`Deleted-${res2?.data?.message.split(":")[1]}`);
      const updatedClients = [...formik?.values?.clients];
      const updatedDocuments = updatedClients[clientIndex].expenses[
        expenseIndex
      ].documents.filter((file) => file.filenames !== fileName);
      updatedClients[clientIndex].expenses[expenseIndex].documents =
        updatedDocuments;

      // Update the Formik field value
      formik.setFieldValue(
        `clients[${clientIndex}].expenses[${expenseIndex}].documents`,
        updatedDocuments
      );

      // Force re-render to reflect the updated data
      setRenderComponent(!renderComponent);
    }
  };

  const handleDownloadFile = async (fileName, clientIndex, expenseIndex) => {
    const endpoint = `downloadfile/${fileName}`; // Adjust the endpoint to your server implementation
 
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/downloadfile/${fileName}`, { responseType: "blob" });
 
      const blob = new Blob([response.data]);
 
      const url = window.URL.createObjectURL(blob);
 
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
 
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    } catch (error) {
      console.error("Error while downloading the file:", error);
    }
  };



  const handleViewFile = async (fileName, clientIndex, expenseIndex) => {
    const endpoint = `/viewfile/${fileName}`;
    const parts = fileName.split(".");

    // Get the last part of the array, which is the file extension
    const fileExtension = parts[parts.length - 1];
    let finalExtension;
    if (fileExtension === "jpg") {
      finalExtension = "image/jpg";
    } else if (fileExtension === "pdf") {
      finalExtension = "application/pdf";
    } else if (fileExtension === "png") {
      finalExtension = "image/png";
    } else if (fileExtension === "jpeg") {
      finalExtension = "image/jpeg";
    }
    try {
      const res = await axios
        .get(`${process.env.REACT_APP_BASE_URL}/viewfile/${fileName}`, {
          responseType: "arraybuffer",
        })
        .then((response) => {
          // Create a Blob from the binary data
          const blob = new Blob([response.data], { type: `${finalExtension}` });

          // Create a URL for the blob
          const fileUrl = URL.createObjectURL(blob);
          window.open(fileUrl, "_blank");
        });
    } catch (error) {
      console.error("Error while fetching image data:", error);
    }
  };

  const ExampleCustomInput = forwardRef(
    ({ value, onClick, disabled, validate }, ref) => (
      <button
        type="button"
        // className="form-control btn btn-sm form-control-sm"
        className="form-control btn btn-sm form-control-sm "
        style={{
          display: "block",
          width: "8rem",
          minHeight: "1rem",
          padding: "0.375rem 0.75rem",
          color: "#495057",
          backgroundColor: "#fff",
          backgroundClip: " padding-box",
          border: "1px solid #ced4da",
          borderRadius: "0.25rem",
        }}
        onClick={onClick}
        ref={ref}
        disabled={disabled}
      >
        {value === "" ? "dd/mm/yyyy" : value}
      </button>
    )
  );

  // Assuming you have the form data in formik?.values?.clients[0].expenses
  // Use reduce to calculate the sum of Expense Amount values

  const getExchangeRate = async (
    clientIndex,
    expenseIndex,
    from,
    to,
    date,
    amount
  ) => {
    const local_date = moment.utc(date).local().format("YYYY-MM-DD");
    if (amount && from !== to) {
      // setRateLoading(true);
      const data = { from, to, date: local_date, amount };
      const response = await postRequest("/exchange_rate", data);
      const fieldName = `clients[${clientIndex}].expenses[${expenseIndex}].SettlementAmount`;
      formik.setFieldValue(
        fieldName,
        response.data.result ? response.data.result.toFixed(2) : ""
      );
      const fieldName2 = `clients[${clientIndex}].expenses[${expenseIndex}].ExchangeRate`;
      formik.setFieldValue(
        fieldName2,
        response.data.result ? response.data.info.rate.toFixed(2) : ""
      );

      // setRateLoading(false);
    } else if (amount && from === to) {
      const fieldName = `clients[${clientIndex}].expenses[${expenseIndex}].SettlementAmount`;
      formik.setFieldValue(fieldName, amount ? amount : "");
    }
  };

  return (
    <>
      <div className="container-scroller">
        <Navbar />
        <div className="container-fluid page-body-wrapper full-page-wrapper">
          <Sidebar />
          <div className="main-panel w-100">
            <div
              className="content-wrapper bg-light w-100"
              style={{
                display: "flex",
                borderRadius: "20px",
                // margin: "2rem",
                background: "#6d4199",
                fontSize: "20px",
                fontWeight: "600",
                border: "none",
                width: "100%",
              }}
            >
              {loading && (
                <div class="loader-container">
                  <div class="loader">
                    <img
                      class="logo"
                      src="/assets/images/aceAppsLogo.png"
                      alt="Logo"
                    />
                  </div>
                </div>
              )}

              <div className="row ">
                <div class=" grid-margin stretch-card w-100">
                  <div class="card p-0 m-0" style={{ borderRadius: "20px" }}>
                    <div
                      className="d-flex text-white justify-content-start align-items-center"
                      style={{
                        background: "#3a5dae",
                        height: "6rem",

                        paddingLeft: "1rem",
                        borderTopRightRadius: "10px",
                        color: "white",
                        borderTopLeftRadius: "10px",
                      }}
                    >
                      <span class="">Expense Form</span>
                    </div>
                    <div>
                      <form
                        className="travel_form"
                        action=""
                        // onSubmit={formik?.handleSubmit}
                      >
                        {/* ===============================Travellers==================== */}
                        <div className="travelFormResponsive ">
                          <div className="expnseMain-wrapper">
                            {formik?.values?.clients?.map((client, index) => {
                              const keyId = client?.Id;
                              const clientIndex = index;
                              const sumBillAmount = formik?.values?.clients[
                                index
                              ].expenses.reduce((accumulator, val) => {
                                const ExpenseAmount = parseFloat(
                                  val.SettlementAmount
                                ); // Parse the value as a float
                                return (
                                  accumulator +
                                  (isNaN(ExpenseAmount) ? 0 : ExpenseAmount)
                                ); // Add to accumulator if it's a valid number
                              }, 0);

                              return (
                                <div className="">
                                  <div className="text-end">
                                    <button
                                      className="btn bg-danger text-white btn-xs my-2 mx-1"
                                      onClick={(event) =>
                                        handleRevoke(event, formik?.values?._id)
                                      }
                                    >
                                      Revoke
                                    </button>
                                  </div>

                                  <div
                                    className="row m-0 pt-3 fs-6"
                                    style={{
                                      background: "#eaebf4",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <div className="col-12 col-lg-12 all-wrapper">
                                      <div className="row">
                                        {/* Client Name */}
                                        <div className="col-12 col-lg-3">
                                          <div className="form-group">
                                            <label>
                                              Client Name
                                              <span className="astik">*</span>
                                            </label>
                                            <select
                                              className="form-select form-select-sm"
                                              name={`clients[${index}].client_name`}
                                              onChange={(event) => {
                                                handleClientChange(
                                                  event,
                                                  index
                                                );
                                                formik.handleChange(event);
                                              }}
                                              onBlur={formik.handleBlur}
                                              value={client.client_name}
                                            >
                                              <option value="">
                                                Select Client
                                              </option>
                                              {projects &&
                                                projects?.map(
                                                  (project, index) => (
                                                    <option
                                                      key={index}
                                                      value={
                                                        project?.client_name
                                                      }
                                                    >
                                                      {project?.client_name}
                                                    </option>
                                                  )
                                                )}
                                            </select>
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index]
                                                ?.client_name && (
                                                <small
                                                  className="expenseError"
                                                  style={{
                                                    color: "red",
                                                  }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      ?.client_name
                                                  }
                                                </small>
                                              )}
                                          </div>
                                        </div>
                                        {/* Project ID */}
                                        <div className="col-12 col-lg-3">
                                          <div className="form-group">
                                            <label>Project Id</label>
                                            <span className="astik"> *</span>
                                            <select
                                              className="form-select form-select-sm"
                                              name={`clients[${index}].project_name`}
                                              onChange={(event) => {
                                                formik.handleChange(event);
                                              }}
                                              onBlur={formik.handleBlur}
                                              value={
                                                formik.values.clients[index]
                                                  ?.project_name
                                              }
                                            >
                                              {/* <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                select...
                                              </option> */}
                                              <option
                                                value={client.project_name}
                                              >
                                                {client.project_name}
                                              </option>
                                              {filteredProjectIds[index]?.map(
                                                (project) => (
                                                  <option
                                                    key={project?._id}
                                                    value={
                                                      project?.project_code
                                                    }
                                                  >
                                                    {`${project?.project_code}- ${project?.Description}`}
                                                  </option>
                                                )
                                              )}
                                            </select>
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index]
                                                ?.project_name && (
                                                <small
                                                  className="expenseError"
                                                  style={{
                                                    color: "red",
                                                  }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      ?.project_name
                                                  }
                                                </small>
                                              )}
                                          </div>
                                        </div>
                                        {/* Total */}
                                        <div className="col-12 col-lg-3">
                                          <div className="row">
                                            <div className="form-group">
                                              <label>
                                                Total Settlement Amount
                                              </label>
                                              <input
                                                placeholder=""
                                                className="formInput"
                                                style={{
                                                  backgroundColor: "#D6E4FF",
                                                }}
                                                value={Math.round(
                                                  sumBillAmount
                                                )}
                                                type="number"
                                                readOnly={true}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                        {/* Settlement Currency */}
                                        <div className="col-12 col-lg-3">
                                          <div className="row">
                                            <div className="col-12">
                                              <div className="form-group">
                                                <label>
                                                  Settlement Currency
                                                </label>
                                                <select
                                                  className="formInput"
                                                  type="search"
                                                  name={`clients[${index}].SettlementCurrency`}
                                                  value={
                                                    formik.values.clients[index]
                                                      .SettlementCurrency
                                                  }
                                                  onChange={(event) => {
                                                    return (
                                                      formik.handleChange(
                                                        event
                                                      ),
                                                      getExchangeRate(
                                                        index,
                                                        formik?.values?.clients[
                                                          index
                                                        ]?.SettlementCurrency?.split(
                                                          "_"
                                                        )[1],
                                                        event?.target?.value?.split(
                                                          "_"
                                                        )[1]
                                                      )
                                                    );
                                                  }}
                                                  // onChange={formik.handleChange}
                                                  // onChange={(event) => {
                                                  //   return (
                                                  //     formik.handleChange(
                                                  //       event
                                                  //     ),
                                                  //     getExchangeRate(
                                                  //       index,
                                                  //       formik?.values?.clients[
                                                  //         index
                                                  //       ]?.SettlementCurrency?.split(
                                                  //         "_"
                                                  //       )[1],
                                                  //       event?.target?.value?.split(
                                                  //         "_"
                                                  //       )[1],
                                                  //       formik?.values?.clients[
                                                  //         index
                                                  //       ]?.expenses[index]
                                                  //         ?.ExpenseDate,
                                                  //       formik?.values.clients[
                                                  //         index
                                                  //       ]?.expenses[index]
                                                  //         .ExpenseAmount
                                                  //     )
                                                  //   );
                                                  // }}
                                                  onBlur={formik.handleBlur}
                                                >
                                                  <option value="">
                                                    {
                                                      formik.values.clients[
                                                        index
                                                      ].SettlementCurrency
                                                    }
                                                  </option>
                                                  {currency?.map(
                                                    (val, index) => {
                                                      return (
                                                        <option
                                                          value={`${val.symbol}_${val?.currency_code}`}
                                                        >
                                                          {`${val?.currency_code}
                                                  (${val?.symbol})`}
                                                        </option>
                                                      );
                                                    }
                                                  )}
                                                </select>
                                                {formik.touched.clients?.[index]
                                                  ?.SettlementCurrency &&
                                                  formik.errors.clients?.[index]
                                                    ?.SettlementCurrency && (
                                                    <small
                                                      className="expenseError"
                                                      style={{ color: "red" }}
                                                    >
                                                      {
                                                        formik.errors.clients[
                                                          index
                                                        ].SettlementCurrency
                                                      }
                                                    </small>
                                                  )}
                                              </div>
                                            </div>
                                            {/* <div className="col-6 ">
                                              <label
                                                // for="excelUpload"
                                                for={`file-input-${index}`}
                                              >
                                                <AiOutlineCloudUpload
                                                  style={{ fontSize: "2rem" }}
                                                />{" "}
                                                <small>
                                                  Upload data from Excel
                                                </small>
                                              </label>
                                              <input
                                                type="file"
                                                id={`file-input-${index}`}
                                                className="form-control form-control-sm d-none"
                                                required
                                                onChange={(e) =>
                                                  handleGetExcelData(e, index)
                                                }
                                              />
                                            </div> */}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  {/* <div className="text-dark text-end mt-auto col-12 ">
                                    <button className="btn disabled  btn-sm">
                                      Total :
                                      <span className="fw-bold">
                                        {" "}
                                        {sumBillAmount.toFixed(2)}
                                      </span>
                                    </button>
                                  </div> */}

                                  <div class="row gy-2  m-0 p-2">
                                    {client?.expenses?.map((row, rowIndex) => (
                                      <div className="col-lg-4   expense-wrapper subExpense p-2">
                                        <div className="row">
                                          {/* Expense Date */}
                                          <div class="form-group col-4">
                                            <label className="form-label">
                                              Expense Date{" "}
                                              <span className="astik"> *</span>
                                            </label>
                                            <div>
                                              <DatePicker
                                                dateFormat="dd/MM/yyyy"
                                                peekNextMonth
                                                maxDate={Date.now()}
                                                className="formInput"
                                                showMonthDropdown
                                                showYearDropdown
                                                dropdownMode="select"
                                                showWeekNumbers
                                                selected={
                                                  new Date(
                                                    formik.values.clients[
                                                      index
                                                    ].expenses[
                                                      rowIndex
                                                    ].ExpenseDate
                                                  )
                                                }
                                                onChange={(date) => {
                                                  const fieldName = `clients[${index}].expenses[${rowIndex}].ExpenseDate`;
                                                  formik.setFieldValue(
                                                    fieldName,
                                                    date ? new Date(date) : ""
                                                  ); // Convert and set the date value
                                                }}
                                                customInput={
                                                  <ExampleCustomInput />
                                                }
                                              />
                                            </div>

                                            {/* <input
                                              className="formInput"
                                              type="date"
                                              pattern="\d{4}-\d{2}-\d{2}"
                                              name={`clients[${index}].expenses[${rowIndex}].ExpenseDate`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex]
                                                  .ExpenseDate
                                              }
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                            /> */}
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.touched.clients[index]
                                                .expenses &&
                                              formik.touched.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index] &&
                                              formik.errors.clients[index]
                                                .expenses &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex]
                                                .ExpenseDate && (
                                                <small
                                                  className="expenseError"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      .expenses[rowIndex]
                                                      .ExpenseDate
                                                  }
                                                </small>
                                              )}
                                          </div>
                                          {/* Invoice Number */}
                                          <div class="form-group col-5">
                                            <label for="inputEmail4">
                                              Invoice Number{" "}
                                            </label>
                                            <input
                                              placeholder="invoice Number"
                                              className="formInput"
                                              type="text"
                                              name={`clients[${index}].expenses[${rowIndex}].InvoiceNo`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex].InvoiceNo
                                              }
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.touched.clients[index]
                                                .expenses &&
                                              formik.touched.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index] &&
                                              formik.errors.clients[index]
                                                .expenses &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex].InvoiceNo}
                                          </div>
                                          {/*Billable*/}
                                          <div className="form-group col-3">
                                            <label
                                              className="form-check-label"
                                              htmlFor="inputAddress"
                                              style={{ marginTop: "5px" }}
                                            >
                                              Billable
                                            </label>
                                            <input
                                              className="form-control-input form-check-input-lg"
                                              style={{
                                                marginBottom: "10px",
                                                marginLeft: "10px",
                                              }}
                                              type="checkbox"
                                              name={`clients[${index}].expenses[${rowIndex}].Billable`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex].Billable
                                              }
                                              // value={
                                              //   formik.values.clients[index]
                                              //     ?.expenses[rowIndex]?.Billable
                                              // }
                                              defaultChecked={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex]
                                                  .Billable === "true"
                                                  ? true
                                                  : false
                                              }
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                            />
                                          </div>
                                          {/* Expense Details */}
                                          <div class="form-group col-lg-12 col-12">
                                            <label for="inputAddress2">
                                              Expense Details{" "}
                                              <span className="astik"> *</span>
                                            </label>
                                            <input
                                              className="formInput"
                                              type="text"
                                              placeholder="ExpenseDetails"
                                              name={`clients[${index}].expenses[${rowIndex}].ExpenseDetails`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex]
                                                  .ExpenseDetails
                                              }
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.touched.clients[index]
                                                .expenses &&
                                              formik.touched.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index] &&
                                              formik.errors.clients[index]
                                                .expenses &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex]
                                                .ExpenseDetails && (
                                                <small
                                                  className="expenseError"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      .expenses[rowIndex]
                                                      .ExpenseDetails
                                                  }
                                                </small>
                                              )}
                                          </div>
                                          {/* Category */}
                                          <div class="form-group col-lg-12 col-12">
                                            <label for="inputCity">
                                              Category{" "}
                                              <span className="astik"> *</span>
                                            </label>
                                            <select
                                              className="form-select form-select-sm"
                                              type="text"
                                              name={`clients[${index}].expenses[${rowIndex}].Category`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex].Category
                                              }
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select...
                                              </option>
                                              {category?.map((value, index) => {
                                                return (
                                                  <option value={value}>
                                                    {value}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.touched.clients[index]
                                                .expenses &&
                                              formik.touched.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index] &&
                                              formik.errors.clients[index]
                                                .expenses &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex]
                                                .Category && (
                                                <small
                                                  className="expenseError"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      .expenses[rowIndex]
                                                      .Category
                                                  }
                                                </small>
                                              )}
                                          </div>

                                          {/* Expense_Currency */}
                                          <div class="form-group col-lg-6 col-12">
                                            <label for="inputState">
                                              Expense_Currency{" "}
                                              <span className="astik"> *</span>
                                            </label>
                                            <select
                                              className="formInput"
                                              type="search"
                                              name={`clients[${index}].expenses[${rowIndex}].Expense_Currency`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex]
                                                  .Expense_Currency
                                              }
                                              onChange={(event) => {
                                                return (
                                                  formik.handleChange(event),
                                                  getExchangeRate(
                                                    index,
                                                    rowIndex,
                                                    event?.target?.value?.split(
                                                      "_"
                                                    )[1],
                                                    formik?.values?.clients[
                                                      index
                                                    ]?.SettlementCurrency?.split(
                                                      "_"
                                                    )[1],
                                                    formik?.values?.clients[
                                                      index
                                                    ]?.expenses[rowIndex]
                                                      ?.ExpenseDate,
                                                    formik?.values.clients[
                                                      index
                                                    ]?.expenses[rowIndex]
                                                      .ExpenseAmount
                                                  )
                                                );
                                              }}
                                              onBlur={formik.handleBlur}
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select..
                                              </option>
                                              {currency?.map((val, index) => {
                                                return (
                                                  <option
                                                    value={`${val.symbol}_${val?.currency_code}`}
                                                  >
                                                    {`${val?.currency_code}
                                                  (${val?.symbol})`}
                                                  </option>
                                                );
                                              })}
                                            </select>
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.touched.clients[index]
                                                .expenses &&
                                              formik.touched.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index] &&
                                              formik.errors.clients[index]
                                                .expenses &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex]
                                                .Expense_Currency && (
                                                <small
                                                  className="expenseError"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      .expenses[rowIndex]
                                                      .Expense_Currency
                                                  }
                                                </small>
                                              )}
                                          </div>

                                          {/* Expense Amount */}
                                          <div class="form-group col-lg-6 col-12">
                                            <label for="inputState">
                                              Expense Amount{" "}
                                              <span className="astik"> *</span>
                                            </label>
                                            <input
                                              placeholder="Expense Amount"
                                              className="formInput"
                                              type="number"
                                              name={`clients[${index}].expenses[${rowIndex}].ExpenseAmount`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex]
                                                  .ExpenseAmount
                                              }
                                              onChange={(event) => {
                                                const value =
                                                  event.target.value;
                                                // Use a regular expression to ensure up to 2 decimal places
                                                const sanitizedValue =
                                                  value.replace(/[^0-9.]*/, ""); // Remove non-numeric and non-decimal characters
                                                const decimalParts =
                                                  sanitizedValue.split(".");
                                                if (decimalParts.length > 1) {
                                                  const integerPart =
                                                    decimalParts[0];
                                                  const decimalPart =
                                                    decimalParts[1].slice(0, 2); // Limit decimal places to 2
                                                  event.target.value = `${integerPart}.${decimalPart}`;
                                                } else {
                                                  event.target.value =
                                                    sanitizedValue;
                                                }

                                                // Continue with other logic (e.g., calling getExchangeRate)
                                                formik.handleChange(event);
                                                getExchangeRate(
                                                  index,
                                                  rowIndex,
                                                  formik?.values?.clients[
                                                    index
                                                  ]?.expenses[
                                                    rowIndex
                                                  ]?.Expense_Currency?.split(
                                                    "_"
                                                  )[1],
                                                  formik?.values?.clients[
                                                    index
                                                  ]?.SettlementCurrency?.split(
                                                    "_"
                                                  )[1],
                                                  formik?.values?.clients[index]
                                                    ?.expenses[rowIndex]
                                                    ?.ExpenseDate,
                                                  event.target.value
                                                );
                                              }}
                                              onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.touched.clients[index]
                                                .expenses &&
                                              formik.touched.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index] &&
                                              formik.errors.clients[index]
                                                .expenses &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex]
                                                .ExpenseAmount && (
                                                <small
                                                  className="expenseError"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      .expenses[rowIndex]
                                                      .ExpenseAmount
                                                  }
                                                </small>
                                              )}
                                          </div>

                                          {/* Exchange Amount */}
                                          {/*<div class="form-group col-lg-6 col-12">
                                            <label for="inputState">
                                              Settlement Currency{" "}
                                              <span className="astik"> *</span>
                                            </label>
                                            <select
                                              className="formInput"
                                              type="search"
                                              name={`clients[${index}].expenses[${rowIndex}].SettlementCurrency`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex]
                                                  .SettlementCurrency
                                              }
                                              onChange={(event) => {
                                                return (
                                                  formik.handleChange(event),
                                                  getExchangeRate(
                                                    index,
                                                    rowIndex,
                                                    formik?.values?.clients[
                                                      index
                                                    ]?.expenses[
                                                      rowIndex
                                                    ]?.Expense_Currency?.split(
                                                      "_"
                                                    )[1],
                                                    event?.target?.value?.split(
                                                      "_"
                                                    )[1],
                                                    formik?.values?.clients[
                                                      index
                                                    ]?.expenses[rowIndex]
                                                      ?.ExpenseDate,
                                                    formik?.values.clients[
                                                      index
                                                    ]?.expenses[rowIndex]
                                                      .ExpenseAmount
                                                  )
                                                );
                                              }}
                                              onBlur={formik.handleBlur}
                                            >
                                              <option
                                                value=""
                                                selected
                                                disabled
                                              >
                                                Select..
                                              </option>
                                              {currency?.map((val, index) => {
                                                return (
                                                  <option
                                                    value={`${val.symbol}_${val?.currency_code}`}
                                                  >
                                                    {`${val?.currency_code}
                                                  (${val?.symbol})`}
                                                  </option>
                                                );
                                              })}
                                            </select>

                                            {formik.touched.clients?.[index]
                                              ?.expenses?.[rowIndex]
                                              ?.SettlementCurrency &&
                                              formik.errors.clients?.[index]
                                                ?.expenses?.[rowIndex]
                                                ?.SettlementCurrency && (
                                                <>
                                                  <small
                                                    className="expenseError"
                                                    style={{ color: "red" }}
                                                  >
                                                    {
                                                      formik.errors.clients[
                                                        index
                                                      ].expenses[rowIndex]
                                                        .SettlementCurrency
                                                    }
                                                  </small>
                                                </>
                                              )}
                                                  </div>*/}

                                          <div class="form-group col-lg-12 col-12">
                                            {/*<label for="inputState">
                                              Settlement Amount{" "}
                                            </label>
                                            <input
                                              placeholder=""
                                              className="formInput"
                                              type="number"
                                              readOnly={true}
                                              oninput="this.value = this.value.replace(/[^0-9]/g, '')"
                                              name={`clients[${index}].expenses[${rowIndex}].SettlementAmount`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex]
                                                  .SettlementAmount
                                              }
                                              onChange={(event) => {
                                                return (
                                                  formik.handleChange(event),
                                                  getExchangeRate(
                                                    event.target.value
                                                  )
                                                );
                                              }}
                                              onBlur={formik.handleBlur}
                                            />*/}
                                            {formik.touched.clients?.[index]
                                              ?.expenses?.[rowIndex]
                                              ?.SettlementAmount &&
                                              formik.errors.clients?.[index]
                                                ?.expenses?.[rowIndex]
                                                ?.SettlementAmount && (
                                                <small
                                                  className="expenseError"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      .expenses[rowIndex]
                                                      .SettlementAmount
                                                  }
                                                </small>
                                              )}
                                            {formik.values.clients[index]
                                              .expenses[rowIndex]
                                              .ExchangeRate && (
                                              <small
                                                className="text-success"
                                                style={{ fontSize: "10px" }}
                                              >
                                                `Exchange Rate used{" "}
                                                <span className="fw-bold fs-6 text-dark">
                                                  {
                                                    formik.values.clients[index]
                                                      .expenses[rowIndex]
                                                      .ExchangeRate
                                                  }{" "}
                                                  {
                                                    formik.values.clients[
                                                      index
                                                    ].expenses[
                                                      rowIndex
                                                    ].Expense_Currency?.split(
                                                      "_"
                                                    )[1]
                                                  }{" "}
                                                </span>
                                                to{" "}
                                                <span className="fw-bold fs-6 text-dark">
                                                  {
                                                    formik.values.clients[
                                                      index
                                                    ].SettlementCurrency?.split(
                                                      "_"
                                                    )[1]
                                                  }
                                                </span>
                                                , expense amount in settlement
                                                currency is{" "}
                                                <span className="fw-bold fs-6 text-dark">
                                                  {
                                                    formik.values.clients[index]
                                                      .expenses[rowIndex]
                                                      .SettlementAmount
                                                  }{" "}
                                                </span>
                                                {
                                                  formik.values.clients[
                                                    index
                                                  ].SettlementCurrency?.split(
                                                    "_"
                                                  )[1]
                                                }
                                                `
                                              </small>
                                            )}
                                          </div>

                                          {/* Remarks */}
                                          <div class="form-group col-lg-12 col-12">
                                            <label for="inputState">
                                              Remarks{" "}
                                            </label>
                                            <textarea
                                              placeholder="Describe in more detail"
                                              // cols={50}
                                              className="formTextArea"
                                              type="text"
                                              name={`clients[${index}].expenses[${rowIndex}].Remarks`}
                                              value={
                                                formik.values.clients[index]
                                                  .expenses[rowIndex].Remarks
                                              }
                                              onChange={formik.handleChange}
                                              onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.clients &&
                                              formik.touched.clients[index] &&
                                              formik.touched.clients[index]
                                                .expenses &&
                                              formik.touched.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients &&
                                              formik.errors.clients[index] &&
                                              formik.errors.clients[index]
                                                .expenses &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex] &&
                                              formik.errors.clients[index]
                                                .expenses[rowIndex].Remarks && (
                                                <small
                                                  className="expenseError"
                                                  style={{ color: "red" }}
                                                >
                                                  {
                                                    formik.errors.clients[index]
                                                      .expenses[rowIndex]
                                                      .Remarks
                                                  }
                                                </small>
                                              )}
                                          </div>
                                          {/* File Upload */}
                                          <div class="form-group col-lg-12 col-12 mt-2">
                                            <div class="fileUpload">
                                              <label
                                                for={`file-input-${index}-${rowIndex}`}
                                              >
                                                <AiOutlineCloudUpload
                                                  style={{
                                                    fontSize: "2rem",
                                                  }}
                                                />
                                              </label>
                                              <span
                                                className="text-primary ms-3 "
                                                style={{ fontSize: "11px" }}
                                              >
                                                (only jpeg, jpg, pdf, png files
                                                are allowed )
                                              </span>

                                              <input
                                                id={`file-input-${index}-${rowIndex}`}
                                                name={`clients[${index}].expenses[${rowIndex}].documents`}
                                                onChange={(event) =>
                                                  handleFileChange(
                                                    event,
                                                    index,
                                                    rowIndex
                                                  )
                                                }
                                                onBlur={formik.handleBlur}
                                                type="file"
                                                class=""
                                              />
                                            </div>
                                            {formik?.values?.clients[
                                              index
                                            ]?.expenses[
                                              rowIndex
                                            ]?.documents?.map((file) => {
                                              return (
                                                <small
                                                  id="emailHelp"
                                                  class="form-text text-sm text-center text-muted"
                                                >
                                                  <span>
                                                    {file?.originalname}
                                                  </span>
                                                  <span className="d-flex">
                                                    {formik?.values
                                                      ?.Managers_approval ===
                                                      "Pending" && (
                                                      <AiOutlineDelete
                                                        disabled
                                                        onClick={() =>
                                                          handleDeleteFile(
                                                            file?.filenames,
                                                            index,
                                                            rowIndex
                                                          )
                                                        }
                                                      />
                                                    )}
                                                    <AiOutlineEye
                                                      className="mx-4"
                                                      onClick={() =>
                                                        handleViewFile(
                                                          file?.filenames,
                                                          index,
                                                          rowIndex
                                                        )
                                                      }
                                                    />
                                                     <AiOutlineDownload
          className="mx-4"
          onClick={() => handleDownloadFile(file?.filenames, index, rowIndex)}
        />

                                                  </span>

                                                  <br />
                                                </small>
                                              );
                                            })}
                                            <small></small>

                                            <div>
                                              {formik.touched.clients &&
                                                formik.touched.clients[index] &&
                                                formik.touched.clients[index]
                                                  .expenses &&
                                                formik.touched.clients[index]
                                                  .expenses[rowIndex] &&
                                                formik.errors.clients &&
                                                formik.errors.clients[index] &&
                                                formik.errors.clients[index]
                                                  .expenses &&
                                                formik.errors.clients[index]
                                                  .expenses[rowIndex] &&
                                                formik.errors.clients[index]
                                                  .expenses[rowIndex]
                                                  .documents && (
                                                  <small
                                                    className="expenseError"
                                                    style={{
                                                      color: "red",
                                                      margin: 0,
                                                      padding: 0,
                                                    }}
                                                  >
                                                    {
                                                      formik.errors.clients[
                                                        index
                                                      ]?.expenses[rowIndex]
                                                        ?.documents
                                                    }
                                                  </small>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <div>
                                            {rowIndex ===
                                              client?.expenses?.length - 1 && (
                                              <button
                                                type="submit"
                                                class="btn bg-white border-0 btn-sm"
                                                onClick={(e) =>
                                                  deleteExpenseRow(
                                                    e,
                                                    index,
                                                    rowIndex
                                                  )
                                                }
                                              >
                                                <div class="sign">
                                                  <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    viewBox="0 0 30 30"
                                                    width="30px"
                                                    height="30px"
                                                  >
                                                    <path d="M 13 3 A 1.0001 1.0001 0 0 0 11.986328 4 L 6 4 A 1.0001 1.0001 0 1 0 6 6 L 24 6 A 1.0001 1.0001 0 1 0 24 4 L 18.013672 4 A 1.0001 1.0001 0 0 0 17 3 L 13 3 z M 6 8 L 6 24 C 6 25.105 6.895 26 8 26 L 22 26 C 23.105 26 24 25.105 24 24 L 24 8 L 6 8 z" />
                                                  </svg>
                                                </div>
                                              </button>
                                            )}
                                          </div>
                                          <div>
                                            {" "}
                                            {rowIndex ===
                                              client.expenses.length - 1 && (
                                              <div className="d-flex justify-content-end my-1">
                                                <button
                                                  type="submit"
                                                  class="btn bg-white  border-0 btn-sm"
                                                  onClick={() =>
                                                    addExpenseRow(index)
                                                  }
                                                >
                                                  <div class="sign mx-1">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 24 24"
                                                      width="24px"
                                                      height="24px"
                                                      fill-rule="evenodd"
                                                    >
                                                      <path
                                                        fill-rule="evenodd"
                                                        d="M 11 2 L 11 11 L 2 11 L 2 13 L 11 13 L 11 22 L 13 22 L 13 13 L 22 13 L 22 11 L 13 11 L 13 2 Z"
                                                      />
                                                    </svg>
                                                  </div>
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* ===================================Travel============================ */}
                        </div>

                        <div className="text-center">
                          <button
                            className="btn btn-primary mx-1"
                            onClick={(e) => {
                              e.preventDefault();
                              handleFormSave();
                            }}
                          >
                            Save
                          </button>
                          <button
                            type="submit"
                            className="btn btn-primary mx-1"
                            onClick={formik?.handleSubmit}
                          >
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* =============Collapse================= */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit_Drafted_User_Expense;
